import React, { useContext } from 'react'
import injectSheet from 'react-jss'
import NavItem from '../nav-item/nav-item.jsx'
import HeaderContext from '../context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'

const UnauthorisedMenu = injectSheet({
  list: {
    listStyle: 'none',
    padding: '0',
    margin: '0',
    '@media (min-width: 769px)': {
      display: 'flex'
    }
  },
  signIn: {
    background: 'rgba(255, 255, 255, 0.1)'
  }
})(({ classes }) => {
  const { appUrl } = useContext(HeaderContext)
  return (
    <div>
      <ul className={classes.list}>
        <NavItem
          className={classes.signIn}
          icon={<FontAwesomeIcon icon={faUser} />}
          url={`${appUrl}/auth/`}>
          Sign in to my account
        </NavItem>
      </ul>
    </div>
  )
})

export default UnauthorisedMenu
