import React, { createContext, useMemo, useState } from 'react'
import { SubscriptionDialogs } from 'pobble-components/dist/subscriptions'
import { useMe } from 'pobble-components/dist/pobble-user'

export const SubscriptionContext = createContext(null)

const SubscriptionProvider = ({ children }) => {
  const { me } = useMe()
  const [subsDialogState, setSubsDialogState] = useState({
    open: false,
    policy: null,
    trigger: null
  })
  const value = useMemo(() => {
    return {
      subsDialogState,
      setSubsDialogState
    }
  }, [subsDialogState, setSubsDialogState])
  return !me ? (
    children
  ) : (
    <SubscriptionContext.Provider value={value}>
      {children}
      <SubscriptionDialogs />
    </SubscriptionContext.Provider>
  )
}

export default SubscriptionProvider
