import THEME from 'pobble-components/dist/theme'

const { COLORS } = THEME

const styles = {
  circleStatus: {
    display: 'inline-flex',
    width: '30px',
    height: '30px',
    border: '1px solid currentColor',
    borderRadius: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: COLORS.RED.NORMAL
  },
  statusSuccess: {
    color: COLORS.GREEN.NORMAL
  },
  statusPending: {
    color: COLORS.YELLOW.NORMAL
  },
  icon: {
    width: '12px',
    height: '12px'
  }
}

export default styles
