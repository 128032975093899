export default {
  wrapper: {
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      background: 'rgba(255, 255, 255, 0.7)',
      display: 'none'
    }
  },
  pending: {
    '&:after': {
      animation: 'fadeIn 0.3s',
      display: 'block'
    }
  },
  container: {
    width: '100%',
    fontSize: '16px',
    lineHeight: '20px',
    '& tbody tr:first-child td': {
      borderTop: '1px solid #ECECEC'
    }
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  }
}
