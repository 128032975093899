import React from 'react'
import injectSheet from 'react-jss'
import styles from './page-not-found.styles'
import classNames from 'classnames'
import Button from 'pobble-components/dist/button'
import pageMonsterWally from 'pobble-components/dist/page-not-found/page-monster-wally.png'

const PageNotFound = ({ classes, className }) => (
  <div className={classNames(classes.container, className)}>
    <div className={classes.holder}>
      <div className={classes.descHolder}>
        <h2 className={classes.title}>
          This page doesn't
          <br />
          seem to exist
        </h2>
        <p className={classes.paragraph}>
          Our page monster
          <br />
          gets hungry sometimes.
        </p>
        <Button tall href="/lessons/prompt">
          Get back to Pobble
        </Button>
        <p className={classes.status}>404</p>
      </div>
      <img
        className={classes.img}
        src={pageMonsterWally}
        alt="Page monster Wally"
      />
    </div>
  </div>
)

export default injectSheet(styles)(PageNotFound)
