const styles = {
  img: {
    width: '200px',
    height: '200px',
    margin: '0 0 20px'
  },
  button: {
    paddingLeft: '40px',
    paddingRight: '40px'
  }
}

export default styles
