import React from 'react'
import injectSheet from 'react-jss'
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter
} from 'pobble-components/dist/modal'
import Button from 'pobble-components/dist/button'
import { URLS } from 'pobble-components/dist/setup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'

const styles = ({ COLORS, FONT_SIZES }) => ({
  titleIcon: {
    margin: '0 8px 0 0',
    color: COLORS.TEXT.LIGHT_GREY.NORMAL
  },
  modalContent: {
    padding: '40px 20px',
    ...FONT_SIZES.BODY_LARGE.STYLE
  },
  overlay: {
    zIndex: '9999'
  },
  list: {
    margin: '24px 0',
    padding: '0 0 0 16px',
    '& > li': {
      marginTop: '24px',
      '&:first-child': {
        margin: '0'
      }
    }
  }
})

const DueInvoiceDialog = ({ classes, blocked, ...props }) => (
  <Modal overlayClassName={classes.overlay} closeButton={!blocked} {...props}>
    <ModalHeader>
      <span className={classes.title}>
        <FontAwesomeIcon
          className={classes.titleIcon}
          icon={faExclamationTriangle}
        />
        We have not received your payment yet
      </span>
    </ModalHeader>
    <ModalContent className={classes.modalContent}>
      You have an overdue payment. Please settle this today by one of the
      following methods:
      <br />
      <ol className={classes.list}>
        <li>
          <strong>Credit or debit card</strong> - click “Make my payment” and
          select “Payment methods” to add or update your card details.
        </li>
        <li>
          <strong>Bank transfer or cheque</strong> - follow the instructions on
          the invoice that was emailed to you to pay by bank transfer or cheque.
        </li>
      </ol>
      If we do not receive your payment or a confirmation that a bank transfer
      or cheque has been sent, we may limit or cancel your account. Please email{' '}
      <strong>hello@pobble.com</strong> with any questions.
    </ModalContent>
    <ModalFooter alignRight childrenGap>
      {!blocked && (
        <Button tall simple action={props.requestClose}>
          Close
        </Button>
      )}
      <Button tall href={`${URLS.ACCOUNT}/membership`}>
        Make my payment
      </Button>
    </ModalFooter>
  </Modal>
)

export default injectSheet(styles)(DueInvoiceDialog)
