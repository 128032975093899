import React from 'react'
import styles from './container.styles'
import injectSheet from 'react-jss'
import classNames from 'classnames'

const Container = ({ classes, children, className }) => (
  <div className={classNames(classes.container, className)}>
    <div className={classes.holder}>{children}</div>
  </div>
)

export default injectSheet(styles)(Container)
