import React, { Component } from 'react'
import PobbleUserContext from '../context'
import { gtmDataLayer } from 'pobble-components/dist/metrics'
import { Modal, ModalContent } from 'pobble-components/dist/modal'
import Button from 'pobble-components/dist/button'
import injectSheet from 'react-jss'
import { URLS } from 'pobble-components/dist/setup'
import schoolIcon from 'pobble-components/dist/pobble-user/school.gif'
import THEME from 'pobble-components/dist/theme'

const { FONT_SIZES, COLORS } = THEME

const schoolRequiredStyles = {
  content: {
    textAlign: 'center',
    minWidth: '460px',
    padding: '100px 0',
    '@media (max-width: 575px)': {
      minWidth: 'auto'
    },
    '& > h2': {
      ...FONT_SIZES.HEADING_BIG.STYLE,
      margin: '0 0 14px'
    },
    '& > p': {
      margin: '0 0 28px'
    }
  },
  icon: {
    width: '100px',
    marginBottom: '28px',
    color: COLORS.TEXT.LIGHT_GREY.NORMAL
  },
  modalOverlay: {
    zIndex: '9999'
  }
}

const SchoolRequiredModal = injectSheet(schoolRequiredStyles)(
  ({ classes, ...props }) => {
    return (
      <Modal overlayClassName={classes.modalOverlay} {...props} closeButton>
        <ModalContent legacy className={classes.content}>
          <img src={schoolIcon} alt="School" className={classes.icon} />
          <h2>
            You need to be a part of
            <br />a school to access this
          </h2>
          <p>
            If you haven't already done so,
            <br />
            please request access to a school
            <br />
            from your profile page.
          </p>
          <Button tall href={`${URLS.ACCOUNT}#school`}>
            Request school access
          </Button>
        </ModalContent>
      </Modal>
    )
  }
)

const onUserDataFetched = user => {
  const { id } = user.account
  gtmDataLayer({
    userId: id,
    pobble_id: id
  })
}

class UserProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: {
        me: null,
        request: { pending: true, error: null },
        refetchMe: this.fetchMe,
        schoolRequiredOpen: false,
        showSchoolRequiredDialog: () =>
          this.setState({
            value: {
              ...this.state.value,
              schoolRequiredOpen: true
            }
          }),
        updateMe: updater =>
          this.setState({
            value: {
              ...this.state.value,
              me: updater(this.state.value.me)
            }
          })
      }
    }
  }
  fetchMe = () => {
    const { fetchMe } = this.props
    const { value } = this.state
    this.setState({
      value: { ...value, request: { pending: true, error: null } }
    })
    return fetchMe()
      .then(res => {
        onUserDataFetched(res.data)
        this.setState({
          value: {
            ...value,
            request: { pending: false, error: null },
            me: res.data
          }
        })
        return res
      })
      .catch(err => {
        this.setState({
          value: {
            ...value,
            request: { pending: false, error: err.response || err }
          }
        })

        // Do not throw an error if user just unauthorized
        if (err.response.status !== 401) {
          throw err
        }
      })
  }
  componentDidMount() {
    this.fetchMe()
  }
  render() {
    const { children } = this.props
    const { value } = this.state
    return (
      <PobbleUserContext.Provider value={value}>
        {children}
        <SchoolRequiredModal
          requestClose={() => {
            this.setState({
              value: {
                ...this.state.value,
                schoolRequiredOpen: false
              }
            })
          }}
          isOpen={this.state.value.schoolRequiredOpen}
        />
      </PobbleUserContext.Provider>
    )
  }
}

export default UserProvider
