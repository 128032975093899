const createReducer = (defaultState, obj) => {
  return (state = defaultState, action) => {
    if (obj && obj[action.type]) return obj[action.type](state, action)
    return state
  }
}

const createRequestReducer = (prefix, defaultState, listeners) =>
  createReducer(
    {
      prefix: prefix,
      pending: false,
      requestError: false,
      params: null,
      ...defaultState
    },
    {
      [`${prefix}_REQUEST`]: (state, { params }) => ({
        ...state,
        params: params || null,
        pending: true,
        requestError: null
      }),
      [`${prefix}_REQUEST_SUCCESS`]: state => ({
        ...state,
        pending: false
      }),
      [`${prefix}_REQUEST_ERROR`]: (state, action) => ({
        ...state,
        pending: false,
        requestError: action.error
      }),
      [`${prefix}_CLEAR_REQUEST_ERROR`]: state => ({
        ...state,
        pending: false,
        requestError: null
      }),
      ...listeners
    }
  )

const createMappedRequestReducer = (prefix, designTree, listeners) =>
  createReducer(
    {},
    {
      [`${prefix}_REQUEST`]: (state, { params }) =>
        !designTree
          ? {
              ...state,
              [params.id]: { pending: true, requestError: null, params }
            }
          : designTree(state, params, { pending: true, requestError: null }),
      [`${prefix}_REQUEST_SUCCESS`]: (state, { params }) =>
        !designTree
          ? {
              ...state,
              [params.id]: { pending: false, requestError: null, params }
            }
          : designTree(state, params, { pending: false, requestError: null }),
      [`${prefix}_REQUEST_ERROR`]: (state, { error, params }) =>
        !designTree
          ? {
              ...state,
              [params.id]: { pending: false, requestError: error, params }
            }
          : designTree(state, params, { pending: false, requestError: error }),
      ...listeners
    }
  )

const requestMiddleware = ({ dispatch }) => next => action => {
  if (!action.hasOwnProperty('requestPrefix')) {
    return next(action)
  }
  return new Promise((resolve, reject) => {
    dispatch({ type: `${action.requestPrefix}_REQUEST`, params: action.params })
    action
      .request()
      .then(data => {
        dispatch({
          type: `${action.requestPrefix}_REQUEST_SUCCESS`,
          response: data,
          params: action.params
        })
        action.onSuccess && action.onSuccess(dispatch, data)
        resolve(data)
      })
      .catch(error => {
        dispatch({
          type: `${action.requestPrefix}_REQUEST_ERROR`,
          error,
          params: action.params
        })
        action.onError && action.onError(dispatch, error)
        reject(error)
      })
  })
}

const clearRequestError = prefix => ({
  type: `${prefix}_CLEAR_REQUEST_ERROR`
})

const getValidationErrors = state =>
  state.requestError
    ? state.requestError.validation
      ? state.requestError.fields
      : {}
    : {}

export {
  createReducer,
  requestMiddleware,
  createRequestReducer,
  createMappedRequestReducer,
  getValidationErrors,
  clearRequestError
}
