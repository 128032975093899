import { fullVHExceptHeader } from 'pobble-components/dist/jss-mixins'
import THEME from 'pobble-components/dist/theme'

const { COLORS, FONT_SIZES } = THEME

export default {
  container: {
    ...fullVHExceptHeader,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  holder: {
    display: 'flex',
    alignItems: 'center',
    [`@media (max-width: 1023px)`]: {
      flexDirection: 'column'
    }
  },
  img: {
    width: '320px',
    height: '320px',
    margin: '0 0 0 50px',
    [`@media (max-width: 1023px)`]: {
      margin: '0',
      order: '1',
      width: '250px',
      height: '250px'
    }
  },
  descHolder: {
    color: COLORS.TEXT.DARK_GREY.NORMAL,
    ...FONT_SIZES.BODY_LARGE.STYLE,
    textAlign: 'center',
    maxWidth: '300px',
    [`@media (max-width: 1023px)`]: {
      order: '2'
    }
  },
  title: {
    color: COLORS.DARK_BLUE.NORMAL,
    fontWeight: 'bold',
    ...FONT_SIZES.HEADING_LARGE.STYLE,
    margin: '0 0 12px'
  },
  paragraph: {
    margin: '0 0 18px'
  },
  status: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    fontSize: '100px',
    fontWeight: 'bold',
    lineHeight: '1',
    margin: '0',
    color: '#ddd'
  }
}
