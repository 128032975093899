import THEME from 'pobble-components/dist/theme'

const { BREAKPOINTS } = THEME

export default {
  legacy: {
    minWidth: '600px',
    padding: '20px',
    [`@media (max-width: ${BREAKPOINTS.XS})`]: {
      minWidth: 'auto',
      width: 'calc(100vw - 20px)'
    }
  },
  content: {
    maxWidth: '600px',
    padding: '20px',
    width: 'calc(100vw - 20px)'
  }
}
