import THEME from 'pobble-components/dist/theme'

const { COLORS } = THEME

export default {
  validationError: {
    width: '14px',
    zIndex: '20',
    height: '14px',
    color: COLORS.RED.NORMAL,
    '& svg': {
      display: 'block',
      width: '100%',
      height: '100%'
    }
  }
}
