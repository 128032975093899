import React, { useContext, Fragment, memo } from 'react'
import injectSheet from 'react-jss'
import NavItem from '../nav-item/nav-item.jsx'
import Avatar from '../avatar/avatar.jsx'
import Activity from '../activity/activity.jsx'
import HeaderMenuBox from '../header-menu-box/header-menu-box.jsx'
import HeaderMenuLink from '../header-menu-link/header-menu-link.jsx'
import DropMenu from '../drop-menu/drop-menu.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChalkboard } from '@fortawesome/pro-solid-svg-icons/faChalkboard'
import { faSchool } from '@fortawesome/pro-solid-svg-icons/faSchool'
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt'
import { faGlobe } from '@fortawesome/pro-solid-svg-icons/faGlobe'
import { faSignOut } from '@fortawesome/pro-solid-svg-icons/faSignOut'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faFeather } from '@fortawesome/pro-solid-svg-icons/faFeather'
import { faFileCheck } from '@fortawesome/pro-solid-svg-icons/faFileCheck'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import { faLightbulb } from '@fortawesome/pro-solid-svg-icons/faLightbulb'
import HeaderContext from '../context'
import { URLS } from 'pobble-components/dist/setup'

const AuthorisedMenu = injectSheet({
  container: {
    '@media (min-width: 769px)': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  list: {
    '@media (min-width: 769px)': {
      marginRight: '20px',
      display: 'flex',
      alignItems: 'center'
    },
    listStyle: 'none',
    margin: '0',
    padding: '0'
  },
  helpLink: {
    color: 'var(--blue)'
  },
  desktopAvatar: {
    display: 'none',
    '@media (min-width: 769px)': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '4px',
      fontSize: '11px'
    }
  },
  desktopProfileInfo: {
    display: 'none',
    '@media (min-width: 769px)': {
      display: 'block'
    }
  },
  mobileProfileIcon: {
    display: 'none',
    maxWidth: '14px'
  },
  mobileAvatarContainer: {
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #ececec',
    paddingBottom: '20px',
    marginBottom: '20px',
    '@media (min-width: 769px)': {
      display: 'none'
    }
  },
  mobileAvatar: {
    width: '60px',
    height: '60px',
    margin: '0 12px 0 0',
    '& > *': {
      fontSize: '60px !important'
    }
  },
  mobileUserName: {
    color: '#777',
    fontSize: '22px',
    lineHeight: '1',
    margin: '0',
    fontWeight: 'normal'
  },
  mobileUserProfileLink: {
    color: '#00aeef',
    fontSize: '14px',
    lineHeight: '1.5',
    textDecoration: 'none'
  },
  mobileAvatarDesc: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  upgrade: {
    '& svg': {
      color: '#fee433',
      fontSize: '.7em',
      marginRight: '.5em'
    }
  },
  featureRequest: {
    '& a > span:first-child': {
      color: 'var(--yellow)',
      opacity: '1'
    }
  }
})(({ classes }) => {
  const { activePage, me, appUrl } = useContext(HeaderContext)
  const isStudent = me.account.student_type
  const isRoleStudent = me.account.role_in_life === 'student'

  return (
    <div className={classes.container}>
      <div className={classes.mobileAvatarContainer}>
        <Avatar className={classes.mobileAvatar} />
        <div className={classes.mobileAvatarDesc}>
          <h5 className={classes.mobileUserName}>{me.account.display_name}</h5>
        </div>
      </div>
      <ul className={classes.list}>
        {isStudent && (
          <Fragment>
            <NavItem
              active={activePage === 'home'}
              url={`${appUrl}/`}
              Icon={faGlobe}>
              Writing Bank
            </NavItem>
            {me.account.policy.can_see_pupil_lessons && (
              <NavItem
                active={activePage === 'pupil'}
                url={`${appUrl}/pupil`}
                Icon={faChalkboard}>
                Lessons
              </NavItem>
            )}
          </Fragment>
        )}
        {!isStudent && (
          <Fragment>
            <NavItem
              active={activePage === 'prompts'}
              icon={<FontAwesomeIcon icon={faFeather} />}
              subscriptionPolicy={sp => sp.moderation.basic_moderation}
              url={`/lessons/prompt`}>
              Writing prompts
            </NavItem>
            <DropMenu>
              {(Container, Menu) => (
                <Container className={classes.expandable}>
                  <NavItem
                    active={
                      activePage === 'dashboard' ||
                      activePage === 'actions' ||
                      activePage === 'pobble-app'
                    }
                    icon={<FontAwesomeIcon icon={faFileAlt} />}
                    expandable>
                    Children's writing
                  </NavItem>
                  <Menu>
                    <HeaderMenuBox>
                      <HeaderMenuLink
                        href={`${URLS.APP}/lessons/works/all/all-ages`}>
                        Writing bank
                      </HeaderMenuLink>
                      <HeaderMenuLink
                        schoolRequired
                        href={`${URLS.APP}/schools/my-school`}>
                        Celebration pages
                      </HeaderMenuLink>
                      <HeaderMenuLink schoolRequired href={URLS.ACTIONS}>
                        Upload writing
                      </HeaderMenuLink>
                      <HeaderMenuLink schoolRequired href={URLS.DASHBOARD}>
                        My pupils
                      </HeaderMenuLink>
                    </HeaderMenuBox>
                  </Menu>
                </Container>
              )}
            </DropMenu>
            <NavItem
              active={activePage === 'moderation'}
              icon={<FontAwesomeIcon icon={faFileCheck} />}
              subscriptionPolicy={sp => sp.moderation.basic_moderation}
              url={`${URLS.MODERATION}`}>
              Moderation
            </NavItem>
            {!isRoleStudent && (
              <NavItem
                target="_blank"
                url="https://www.pobble.com/webinars"
                className={`${classes.featureRequest}`}
                icon={<FontAwesomeIcon icon={faLightbulb} />}>
                Webinars
              </NavItem>
            )}
          </Fragment>
        )}
      </ul>
      {me.account.policy.can_see_activity_link &&
        me.account.finish_signup_status.finished && (
          <Activity activePage={activePage} appUrl={appUrl} />
        )}
      <DropMenu>
        {(Container, Menu) => (
          <Container className={classes.profileContainer}>
            <div className={classes.desktopAvatar}>
              <Avatar circles />
              <FontAwesomeIcon icon={faCaretDown} />
            </div>
            <Menu className={classes.profileMenu}>
              <HeaderMenuBox
                className={classes.desktopProfileInfo}
                title={me.account.display_name}
              />
              <HeaderMenuBox className={classes.profileHelpBox}>
                {me.account.policy.can_see_profile_link && (
                  <HeaderMenuLink href={URLS.ACCOUNT}>
                    <FontAwesomeIcon
                      icon={faUser}
                      className={classes.mobileProfileIcon}
                    />
                    My account
                  </HeaderMenuLink>
                )}
                {me.account.policy.can_see_subscriptions && (
                  <HeaderMenuLink
                    href={`${URLS.ACCOUNT}/membership?from=navigation`}>
                    My subscription
                  </HeaderMenuLink>
                )}
                {me.account.policy.view_school_tab && (
                  <HeaderMenuLink
                    active={activePage === 'schools'}
                    href={`${appUrl}/account/school/`}>
                    <FontAwesomeIcon
                      icon={faSchool}
                      className={classes.mobileProfileIcon}
                    />
                    School settings
                  </HeaderMenuLink>
                )}
                <HeaderMenuLink
                  target="_blank"
                  href="https://help.pobble.com/knowledge">
                  Help desk
                </HeaderMenuLink>
                <HeaderMenuLink
                  target="_blank"
                  href={`https://share.hsforms.com/1FWw0Tw9NQNabYm6pqpbV_Q4oqv9?id=${me.account.id}`}>
                  What's missing?
                </HeaderMenuLink>
              </HeaderMenuBox>
              <HeaderMenuBox className={classes.profileSignoutBox}>
                <HeaderMenuLink href={`${appUrl}/auth/sign-out/`}>
                  <FontAwesomeIcon
                    icon={faSignOut}
                    className={classes.mobileProfileIcon}
                  />
                  Sign out
                </HeaderMenuLink>
              </HeaderMenuBox>
            </Menu>
          </Container>
        )}
      </DropMenu>
    </div>
  )
})

export default memo(AuthorisedMenu)
