import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Manager, Reference, Popper } from 'react-popper'
import injectSheet from 'react-jss'
import styles from './tooltip.styles.js'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import DownArrowIcon from 'react-icons/lib/fa/caret-down'
import UpArrowIcon from 'react-icons/lib/fa/caret-up'

const Tooltip = ({
  classes,
  children,
  content,
  className,
  tooltipClassName,
  danger,
  style,
  as = 'div'
}) => {
  const [opened, setOpened] = useState(false)
  const [transition, setTransition] = useState('closed')
  const transitionTimeout = useRef(null)

  useEffect(() => {
    if (opened) {
      setTransition('opened')
    } else if (transition !== 'closed') {
      setTransition('closing')
      transitionTimeout.current = setTimeout(() => setTransition('closed'), 200)
    }
    return () => {
      clearTimeout(transitionTimeout.current)
    }
  }, [opened])

  const Wrapper = as
  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <Wrapper
            style={style}
            onMouseEnter={() => setOpened(true)}
            onMouseLeave={() => setOpened(false)}
            className={classNames(classes.button, className)}
            ref={ref}>
            {children}
          </Wrapper>
        )}
      </Reference>
      {!!content &&
        transition !== 'closed' &&
        ReactDOM.createPortal(
          <Popper placement="top">
            {({ ref, style, placement, arrowProps }) => {
              return (
                <div
                  ref={ref}
                  style={style}
                  className={classNames(classes.tooltip, tooltipClassName, {
                    [classes.opened]: transition === 'opened',
                    [classes.closing]: transition === 'closing',
                    [classes.danger]: danger
                  })}
                  data-placement={placement}>
                  <div className={classes.body}>
                    {content}
                    <span
                      className={classNames(classes.arrow, {
                        [classes.arrowTop]: placement === 'top',
                        [classes.arrowBottom]: placement === 'bottom'
                      })}
                      ref={arrowProps.ref}
                      style={arrowProps.style}>
                      {placement === 'top' && <DownArrowIcon />}
                      {placement === 'bottom' && <UpArrowIcon />}
                    </span>
                  </div>
                </div>
              )
            }}
          </Popper>,
          document.body
        )}
    </Manager>
  )
}

Tooltip.propTypes = {
  /** Wrapped components will be shown as is */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /** Content to be shown in tooltip. If null no tooltip will be shown */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Additional className for children wrapper to restyle */
  className: PropTypes.string,
  /** Show red tooltip */
  danger: PropTypes.bool
}

export default injectSheet(styles)(Tooltip)
