import React from 'react'
import injectSheet from 'react-jss'
import styles from './modal-footer.styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const ModalFooter = ({
  classes,
  className,
  children,
  alignRight,
  childrenGap
}) => (
  <div
    className={classNames(
      classes.container,
      {
        [classes.alignRight]: alignRight,
        [classes.childrenGap]: childrenGap
      },
      className
    )}>
    <div className={classes.holder}>{children}</div>
  </div>
)

ModalFooter.propTypes = {
  /** Align content to the right */
  alignRight: PropTypes.bool,
  /** Creates a small gap between children, useful when rendering several buttons */
  childrenGap: PropTypes.bool,
  /** Additional className for restyling purposes */
  className: PropTypes.string
}

export default injectSheet(styles)(ModalFooter)
