import React, { useContext, useMemo } from 'react'
import injectSheet from 'react-jss'
import styles from './header.styles'
import Nav from './nav/nav.jsx'
import classNames from 'classnames'
import HeaderContext from './context'
import { URLS } from 'pobble-components/dist/setup'

const Header = ({ classes }) => {
  const { className, pending, me, style, student, preMenuSlot } = useContext(
    HeaderContext
  )
  const isStudent = useMemo(() => me && me.account && me.account.student_type, [
    me
  ])

  return (
    <div
      className={classNames(classes.container, className, {
        [classes.studentHeader]: student || isStudent
      })}
      style={style}>
      <a
        className={classes.logo}
        href={isStudent ? URLS.PUPIL_ACTIVITIES : '/lessons/prompt'}>
        <img
          className={classes.logoIcon}
          src="https://app.pobble.com/account/files/logo-white.svg"
        />
      </a>
      {preMenuSlot}
      {!pending && <Nav me={me} className={classes.nav} />}
    </div>
  )
}

export default injectSheet(styles)(Header)
