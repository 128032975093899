import React from 'react'
import styles from './loading-screen.styles'
import injectSheet from 'react-jss'
import Spinner from '../../../spinner'

const LoadingScreen = ({ classes }) => (
  <div className={classes.container}>
    <Spinner className={classes.spinner} />
  </div>
)

export default injectSheet(styles)(LoadingScreen)
