export default {
  container: {
    color: '#999',
    padding: '20px 10px',
    borderBottom: '1px solid #ECECEC'
  },
  pointer: {
    cursor: 'pointer'
  },
  right: {
    textAlign: 'right'
  },
  center: {
    textAlign: 'center'
  }
}
