import React from 'react'
import injectSheet from 'react-jss'
import styles from './paper.styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Paper = ({ classes, className, children }) => (
  <div className={classNames(classes.paper, className)}>{children}</div>
)

Paper.propTypes = {
  /** Will be rendered inside Paper component */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /** Additional className if you want to restyle component or add some margins */
  className: PropTypes.string
}

export default injectSheet(styles)(Paper)
