import React from 'react'
import injectSheet from 'react-jss'
import styles from './circle-status.styles.js'
import classNames from 'classnames'
import CloseIcon from 'react-icons/lib/fa/close'
import CheckIcon from 'react-icons/lib/fa/check'
import EllipsisIcon from 'react-icons/lib/fa/ellipsis-h'
import PropTypes from 'prop-types'

const CircleStatus = ({ classes, status, className }) => {
  const Icon =
    status === 'pending' ? EllipsisIcon : status ? CheckIcon : CloseIcon
  return (
    <span
      className={classNames(
        classes.circleStatus,
        {
          [classes.statusSuccess]: status !== 'pending' && status,
          [classes.statusPending]: status === 'pending'
        },
        className
      )}>
      <Icon className={classes.icon} />
    </span>
  )
}

CircleStatus.propTypes = {
  /** Current status */
  status: PropTypes.oneOf([true, false, 'pending']).isRequired,
  /** Additional className if you want to restyle component */
  className: PropTypes.string
}

export default injectSheet(styles)(CircleStatus)
