import THEME from 'pobble-components/dist/theme'

const { BREAKPOINTS } = THEME

const styles = {
  menuHolder: {
    [`@media (max-width: ${BREAKPOINTS.SM})`]: {
      background: '#fff',
      transition: 'transform 0.3s, opacity 0.3s',
      transform: 'translate3d(320px, 0, 0)',
      opacity: '0',
      pointerEvents: 'none',
      width: '320px',
      height: 'calc(100vh - 50px)',
      position: 'fixed',
      top: '50px',
      right: '0',
      padding: '20px 30px 120px'
    }
  },
  menuHolderOpened: {
    [`@media (max-width: ${BREAKPOINTS.SM})`]: {
      pointerEvents: 'auto',
      transform: 'translate3d(0, 0, 0)',
      opacity: '1',
      overflow: 'auto'
    }
  },
  list: {
    listStyle: 'none',
    padding: '0',
    margin: '0',
    display: 'flex',
    [`@media (max-width: ${BREAKPOINTS.SM})`]: {
      flexDirection: 'column',
      padding: '0 0 20px',
      borderBottom: '1px solid #ececec'
    }
  },
  toggleMobileMenu: {
    display: 'none',
    cursor: 'pointer',
    [`@media (max-width: ${BREAKPOINTS.SM})`]: {
      display: 'block'
    }
  },
  mobileOverlay: {
    display: 'none',
    transition: 'opacity 0.3s',
    opacity: '0',
    pointerEvents: 'none',
    position: 'fixed',
    height: 'calc(100vh - 50px)',
    width: '100%',
    left: '0',
    top: '50px',
    background: 'rgba(0, 0, 0, 0.3)',
    [`@media (max-width: ${BREAKPOINTS.SM})`]: {
      display: 'block'
    }
  },
  mobileOverlayShown: {
    [`@media (max-width: ${BREAKPOINTS.SM})`]: {
      opacity: '1',
      pointerEvents: 'auto'
    }
  }
}

export default styles
