import React, { useMemo } from 'react'
import Header from './header.jsx'
import PropTypes from 'prop-types'
import { withMe } from 'pobble-components/dist/pobble-user'
import HeaderContext from './context.js'

const HeaderContainer = ({
  me,
  meRequest,
  activePage,
  appUrl = '',
  className,
  style,
  student,
  preMenuSlot
}) => {
  const value = useMemo(() => {
    return {
      authorised: !meRequest.pending && me,
      pending: meRequest.pending,
      me,
      activePage: activePage,
      appUrl,
      className,
      style,
      student,
      preMenuSlot
    }
  }, [
    me,
    meRequest,
    activePage,
    appUrl,
    style,
    student,
    className,
    preMenuSlot
  ])
  return (
    <HeaderContext.Provider value={value}>
      <Header />
    </HeaderContext.Provider>
  )
}

HeaderContainer.propTypes = {
  /**
   * Base url of pobble app
   * Will be used as a prefix to all header links
   * Will fallback to empty string if not set, making all links relative to the domain.
   * Empty string fallback is useful for proxy SPAs that are located under the same domain.
   * Example value: https://stg-app.pobble.com
   */
  appUrl: PropTypes.string,

  /**
   * Currently active page
   */
  activePage: PropTypes.string,

  /**
   * Additional header classname for repositiong
   */
  className: PropTypes.string
}

export default withMe(HeaderContainer)
