import React from 'react'
import styles from './avatar-container.styles'
import injectSheet from 'react-jss'
import ImageAvatar from '../image-avatar/image-avatar'
import InitialsAvatar from '../initials-avatar/initials-avatar'
import StudentAvatar from '../student-avatar/student-avatar'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const AVATARS_MAP = {
  IMAGE: avatar => <ImageAvatar imageUrl={avatar.url} />,
  INITIALS: (avatar, name) => <InitialsAvatar name={name || '?'} />,
  STUDENT: avatar => (
    <StudentAvatar colorRef={avatar.color} codeRef={avatar.code} />
  )
}

const PobbleAvatar = ({ classes, avatar, name, className, style }) => {
  const avatarType = !avatar
    ? 'INITIALS'
    : avatar.url
    ? 'IMAGE'
    : avatar.type === 'StudentAvatar'
    ? 'STUDENT'
    : 'DEFAULT'
  return (
    <div className={classNames(classes.container, className)} style={style}>
      {AVATARS_MAP[avatarType] && AVATARS_MAP[avatarType](avatar, name)}
    </div>
  )
}

PobbleAvatar.propTypes = {
  /** Avatar object coming directly from BE, no need to map anything */
  avatar: PropTypes.object,
  /** The first letter of this property will serve as an initials fallback for avatar */
  name: PropTypes.string,
  /** Additional className if you want to restyle component or add some margins */
  className: PropTypes.string,
  /** Style object to change appearance of the avatar */
  style: PropTypes.object
}

export default injectSheet(styles)(PobbleAvatar)
