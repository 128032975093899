export default {
  container: {
    borderRadius: '0 0 8px 8px',
    padding: '20px',
    color: 'var(--gray-)',
    backgroundColor: 'var(--light-gray--)'
  },
  holder: {
    display: 'flex',
    flexDirection: 'row'
  },
  alignRight: {
    '& $holder': {
      justifyContent: 'flex-end'
    }
  },
  childrenGap: {
    '& $holder': {
      margin: '0 -5px',
      '& > *': {
        marginLeft: '5px',
        marginRight: '5px'
      }
    }
  }
}
