import Cookies from 'js-cookie'
import { COOKIE_SETTINGS, URLS } from 'pobble-components/dist/setup'
import { getPobbleEnv } from 'pobble-components/dist/utils'
import { AUTH_REDIRECT } from 'pobble-components/dist/cookies'
import { apiClient } from 'pobble-components/dist/api'

const setToken = token =>
  Cookies.set('token', token, {
    ...COOKIE_SETTINGS,
    expires: 28 // Token is valid for 28 days in case user selected "Remember me"
  })

const getToken = token => Cookies.get('token', token, COOKIE_SETTINGS)

const unsetToken = () => Cookies.remove('token', COOKIE_SETTINGS)

/**
 * Redirect user to auth in with ability to
 * return back after succesful sign in
 */
const redirectToAuth = ({ redirectUrl, redirectBack, route = '' } = {}) => {
  const redirect =
    redirectBack && window.location.href !== `${URLS.APP}/`
      ? window.location.href
      : redirectUrl

  // Only set redirect url if it matches current domain
  if (redirect && getPobbleEnv() === getPobbleEnv(redirect))
    AUTH_REDIRECT.set(redirect)

  window.location.replace(`${URLS.APP}/auth/${route}`)
  return null
}

/**
 * Redirect user to the platform after succesful login
 * Check if user has to be redirected to some specific place
 *  that was saved before auth redirect
 */
const redirectAfterAuth = () => {
  const redirect = AUTH_REDIRECT.get()

  // Only redirect to the same environmentt
  if (redirect && getPobbleEnv() === getPobbleEnv(redirect)) {
    AUTH_REDIRECT.remove()
    window.location.href = redirect
  } else {
    /**
     * If user signed up from moderation LP redirect to moderation
     *  otherwise redirect to Pobble 365
     */
    apiClient.get('v3/me?include=account.signup_details').then(res => {
      const origin = res?.data?.account?.signup_origin
      if (origin === 'mod') {
        window.location.href = '/moderation'
      } else {
        window.location.href = '/lessons/prompt'
      }
    })
  }
}

export { setToken, getToken, unsetToken, redirectToAuth, redirectAfterAuth }
