import React, { useState } from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import { useSubscriptionPolicy } from 'pobble-components/dist/subscriptions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown } from '@fortawesome/pro-solid-svg-icons/faCrown'
import { useMe } from 'pobble-components/dist/pobble-user'

const stripEndSlash = str =>
  str[str.length - 1] === '/' ? str.slice(0, str.length - 1) : str

const isInActiveState = url =>
  !url ? false : stripEndSlash(window.location.href) === stripEndSlash(url)

const HeaderMenuLink = injectSheet({
  item: {
    '@media (min-width: 769px)': {
      marginTop: '16px',
      lineHeight: '1.2',
      '&:first-child': {
        marginTop: '0'
      }
    }
  },
  premium: {
    '& $link': {
      color: 'var(--gray---)'
    }
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: 'var(--gray)',
    '@media (max-width: 768px)': {
      padding: '4px 0',
      margin: '2px 0'
    },
    '@media (min-width: 769px)': {
      textDecoration: 'none',
      cursor: 'pointer',
      transition: 'color 0.3s',
      '&:hover': {
        color: 'var(--blue)'
      }
    }
  },
  active: {
    color: 'var(--blue)'
  },
  premiumIcon: {
    color: 'var(--gray---)',
    fontSize: '.7em',
    marginRight: '.5em'
  }
})(
  ({
    classes,
    children,
    href,
    target,
    className,
    schoolRequired,
    onClick,
    subscriptionPolicy = () => ({ restricted: false, plan: 'pobble-bronze' })
  }) => {
    const [active] = useState(isInActiveState(href))
    const SP = useSubscriptionPolicy(subscriptionPolicy)
    const { me, showSchoolRequiredDialog } = useMe()
    const hasSchool = me.account.policy.teacher_in_school
    return (
      <li
        className={classNames(classes.item, {
          [classes.premium]: SP.restricted
        })}>
        <a
          href={href}
          target={target}
          onClick={
            onClick
              ? onClick
              : e => {
                  if (SP.restricted) {
                    e.preventDefault()
                    SP.showDialog()
                  } else if (schoolRequired && !hasSchool) {
                    e.preventDefault()
                    showSchoolRequiredDialog()
                  }
                }
          }
          className={classNames(classes.link, className, {
            [classes.active]: active
          })}>
          {SP.restricted && (
            <FontAwesomeIcon className={classes.premiumIcon} icon={faCrown} />
          )}
          {children}
        </a>
      </li>
    )
  }
)

export default HeaderMenuLink
