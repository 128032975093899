import React from 'react'
import injectSheet from 'react-jss'
import styles from './modal-content.styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'

/**
 * Please try to refactor component with legacy prop every time you encounter one
 *   the only change is in the css, check the modal-content.styles.js
 */

const ModalContent = ({ classes, children, className, legacy }) => (
  <div
    className={classNames(classes[legacy ? 'legacy' : 'content'], className)}>
    {children}
  </div>
)

ModalContent.propTypes = {
  /** Additional className for restyling purposes */
  className: PropTypes.string
}

export default injectSheet(styles)(ModalContent)
