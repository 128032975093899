import React, { useState } from 'react'
import withMe from '../with-me.hoc'
import LoadingScreen from '../screens/loading-screen/loading-screen'
import UnknownErrorScreen from '../screens/unknown-error-screen/unknown-error-screen'
import FSUScreen from '../screens/fsu-screen/fsu-screen'
import NoRightsScreen from '../screens/no-rights-screen/no-rights-screen'
import SchoolRequiredScreen from '../screens/school-required-screen/school-required-screen'
import injectSheet from 'react-jss'
import styles from './user-inspector.styles'
import { getPobbleEnv } from 'pobble-components/dist/utils'
import { redirectToAuth } from 'pobble-components/dist/auth-helpers'
import { getToken } from 'pobble-components/dist/auth-helpers'
import { URLS } from 'pobble-components/dist/setup'
import DevPage from 'pobble-components/dist/dev-page'
import { Modal, ModalContent } from 'pobble-components/dist/modal'
import { SelectRole } from 'pobble-components/dist/select-role'

const env = getPobbleEnv()

const redirectToConflictsResolutionTool = () => {
  window.location.replace(`${URLS.APP}/dashboard/conflict-resolution`)
  return null
}

const devMode = env === 'local'

const RoleSelectionModal = injectSheet({
  content: {
    maxWidth: '760px'
  }
})(({ classes }) => {
  const [open, setOpen] = useState(true)
  return (
    <Modal isOpen={open}>
      <ModalContent className={classes.content}>
        <SelectRole
          onSuccess={() => setOpen(false)}
          onError={() => setOpen(false)}
        />
      </ModalContent>
    </Modal>
  )
})

const UserInspector = ({
  classes,
  me,
  meRequest,
  children,
  disableFSU,
  haveRights,
  schoolRequired,
  authRedirect = true
}) => {
  if (!getToken() && !devMode)
    return redirectToAuth({ redirectBack: authRedirect })
  if (meRequest.pending) return <LoadingScreen />
  if (meRequest.error) {
    if (meRequest.error.status === 401) {
      return devMode ? (
        <DevPage />
      ) : (
        redirectToAuth({ redirectBack: authRedirect })
      )
    } else {
      console.error('Pobble user error:', meRequest.error)
      return <UnknownErrorScreen />
    }
  }
  const PullProviderConflictResolution =
    (me.account.policy.pull_provider &&
      me.account.policy.pull_provider.start_conflict_resolution) ||
    (me.account.policy.wonde &&
      me.account.policy.wonde.start_conflict_resolution)
  const { finish_signup_status } = me.account

  if (!disableFSU && !finish_signup_status.finished) return <FSUScreen />
  if (haveRights && !haveRights(me)) return <NoRightsScreen />
  if (schoolRequired && !me.account.policy.teacher_in_school)
    return <SchoolRequiredScreen />

  if (
    PullProviderConflictResolution &&
    window.location.href.indexOf('conflict-resolution') === -1 &&
    window.location.host !== 'admin.pobble.com'
  ) {
    return redirectToConflictsResolutionTool()
  }

  const ROLE_NOT_SELECTED =
    me && me.account && me.account.role_in_life === 'not_selected'

  return (
    <>
      <div className={classes.container}>{children}</div>
      {ROLE_NOT_SELECTED && <RoleSelectionModal />}
    </>
  )
}

export default withMe(injectSheet(styles)(UserInspector))
