import React from 'react'
import styles from './image-avatar.styles'
import injectSheet from 'react-jss'

const ImageAvatar = ({ classes, imageUrl }) => (
  <div
    className={classes.container}
    style={{ backgroundImage: `url(${imageUrl})` }}
  />
)

export default injectSheet(styles)(ImageAvatar)
