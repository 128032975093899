import axios from 'axios'
import { getToken } from 'pobble-components/dist/auth-helpers'

export default (opts = {}) => {
  const { baseUrl } = opts

  const instance = axios.create({
    baseURL: baseUrl
  })

  instance.interceptors.request.use(
    config => {
      const token = getToken()
      if (token) config.headers['Authorization'] = `Bearer: ${token}`
      return config
    },
    error => Promise.reject(error)
  )

  return instance
}
