import React, { Fragment } from 'react'
import styles from './unknown-error-screen.styles'
import injectSheet from 'react-jss'
import FullpageError from 'pobble-components/dist/full-page-error'

const UnauthorisedScreen = () => (
  <FullpageError
    monster="unplug"
    title="There appears to be a technical error"
    status="Oops!"
    desc={
      <Fragment>
        We have logged the error.
        <br />
        If this continues to happen, please
        <br />
        contact hello@pobble.com.
      </Fragment>
    }
  />
)

export default injectSheet(styles)(UnauthorisedScreen)
