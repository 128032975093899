import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './form-radio.styles.js'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class FormRadio extends Component {
  render() {
    const {
      classes,
      name,
      id,
      checked,
      onChange,
      value,
      radioProps,
      disabled,
      ...props
    } = this.props
    return (
      <span
        className={classNames(classes.radioContainer, {
          [classes.disabled]: disabled
        })}>
        <input
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          value={value}
          name={name}
          id={id}
          type="radio"
          className={classes.radioButton}
          {...radioProps}
          {...props}
        />
        <span className={classes.radioBG}>
          <span className={classes.mark} />
        </span>
      </span>
    )
  }
}

FormRadio.propTypes = {
  /** Id of the radio button DOM node */
  id: PropTypes.string,
  /** Name of the radio button DOM node */
  name: PropTypes.string,
  /** Boolean value of checked state. Makes radio button controlled */
  checked: PropTypes.bool,
  /** On radio button status change handler */
  onChange: PropTypes.func,
  /** Additional className if you want to restyle component */
  className: PropTypes.string,
  /** Object with all additional radio props you want to pass. Check official radio documentation to get the idea what props os available */
  radioProps: PropTypes.object
}

export default injectSheet(styles)(FormRadio)
