class Dispatcher {
  dispatch(action) {
    this.subscriber && this.subscriber(action)
  }
  register(fn) {
    this.subscriber = fn
  }
}

export default new Dispatcher()
