import { createReducer } from 'pobble-components/dist/redux-helpers'

const defaultState = {
  opened: false,
  bodyText: 'Are you sure?',
  confirmText: 'Yes',
  cancelText: 'No',
  theme: null,
  action: null
}

const reducer = createReducer(defaultState, {
  'CONFIRMATION_DIALOG:OPEN': (
    state,
    { bodyText, confirmText, cancelText, action, theme }
  ) => ({
    ...state,
    opened: true,
    bodyText: bodyText || defaultState.bodyText,
    confirmText: confirmText || defaultState.confirmText,
    cancelText: cancelText || defaultState.cancelText,
    action: action || defaultState.action,
    theme: theme || defaultState.theme
  }),
  'CONFIRMATION_DIALOG:CLOSE': state => ({
    ...state,
    opened: false
  })
})

export { defaultState, reducer }
