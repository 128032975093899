import React from 'react'
import injectSheet from 'react-jss'
import styles from './subscription-required-dialog.styles'
import { Modal, ModalContent } from 'pobble-components/dist/modal'
import SubscriptionRequired from '../subscripiton-required/subscription-required'

const SubscriptionRequiredDialog = ({
  classes,
  isOpen,
  policy,
  requestClose
}) => (
  <Modal
    overlayClassName={classes.overlay}
    className={classes.modal}
    isOpen={isOpen}
    requestClose={requestClose}
    closeButton>
    <ModalContent legacy className={classes.content}>
      <SubscriptionRequired policy={policy} />
    </ModalContent>
  </Modal>
)

export default injectSheet(styles)(SubscriptionRequiredDialog)
