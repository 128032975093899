import { hover } from 'pobble-components/dist/jss-mixins'

const styles = {
  profileCircles: {},
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '40px',
    cursor: 'pointer',
    position: 'relative',
    ...hover({
      '& $profileCircles > div': {
        '&:nth-child(1)': {
          transform: 'scale(1.4)'
        },
        '&:nth-child(2)': {
          transform: 'scale(2.2)',
          transitionDelay: '0.1s'
        },
        '&:nth-child(3)': {
          transform: 'scale(3.2)',
          transitionDelay: '0.2s'
        },
        '&:nth-child(4)': {
          transform: 'scale(4.2)',
          transitionDelay: '0.3s'
        }
      }
    })
  },
  pobbleAvatar: {
    fontSize: '40px',
    zIndex: '60',
    position: 'relative'
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '100%',
    position: 'relative',
    zIndex: '100'
  },
  profileCircle: {
    pointerEvents: 'none',
    transition: 'transform 1000ms cubic-bezier(0.68, -0.55, 0.265, 1.55)',
    width: '40px',
    height: '40px',
    borderRadius: '40px',
    backgroundColor: 'rgba(255,255,255,0.1)',
    position: 'absolute',
    left: '0',
    top: '0',
    zIndex: '50'
  }
}

export default styles
