import THEME from 'pobble-components/dist/theme'

const { COLORS } = THEME

export default {
  button: {
    alignItems: 'center',
    fontWeight: 'bold',
    outline: 'none',
    textDecoration: 'none',
    display: 'inline-flex',
    borderRadius: '4px',
    border: `1px solid ${COLORS.BLUE.NORMAL}`,
    backgroundColor: COLORS.BLUE.NORMAL,
    height: '30px',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '14px',
    padding: '7px 10px',
    color: '#fff',
    transition: 'background-color .1s ease-in-out, color .1s ease-in-out',
    '&:hover': {
      backgroundColor: COLORS.BLUE.EXTRA_FADED
    },
    '&:active': {
      boxShadow: `0 0 2px 1px ${COLORS.BLUE.EXTRA_FADED}`
    }
  },
  darkBlue: {
    background: 'var(--dark-blue)',
    border: 'none',
    color: '#fff',
    '&:hover, &:focus': {
      background: 'var(--dark-blue-)'
    },
    '&$disabled': {
      opacity: '.3',
      backgroundColor: 'var(--dark-blue)',
      color: '#fff'
    },
    '&$simple': {
      color: 'var(--dark-blue)',
      '&:hover, &:focus': {
        color: 'var(--dark-blue-)'
      }
    }
  },
  minw: {
    minWidth: '100px',
    justifyContent: 'center'
  },
  spinner: {
    fontSize: '.8em',
    color: 'currentColor'
  },
  iconHolder: {
    height: '10px',
    margin: '0 5px 0 0',
    '& > svg': {
      width: 'auto',
      height: '100%',
      display: 'block'
    }
  },
  equalizer: {
    width: '10px',
    height: '10px',
    marginLeft: '5px'
  },
  back: {
    '& $iconHolder': {
      height: '14px',
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  buttonTall: {
    height: '40px',
    padding: '12px 15px',
    '& $iconHolder': {
      height: '12px',
      margin: '0 6px 0 -2px'
    }
  },
  big: {
    height: '50px',
    padding: '15px 20px',
    fontSize: '20px',
    lineHeight: '20px',
    '& $iconHolder': {
      height: '14px',
      margin: '0 6px 0 -4px'
    }
  },
  green: {
    border: `1px solid ${COLORS.GREEN.NORMAL}`,
    backgroundColor: COLORS.GREEN.NORMAL,
    '&:hover': {
      backgroundColor: COLORS.GREEN.FADED
    },
    '&:active': {
      boxShadow: `0 0 2px 1px ${COLORS.GREEN.EXTRA_FADED}`
    },
    '&$secondary': {
      color: COLORS.GREEN.NORMAL,
      '&:hover': {
        color: '#fff'
      }
    },
    '&$simple': {
      color: COLORS.GREEN.NORMAL,
      '&:hover': {
        color: COLORS.GREEN.FADED
      },
      '&:active': {
        color: COLORS.GREEN.NORMAL
      }
    }
  },
  white: {
    border: 'none',
    backgroundColor: 'var(--light-gray--)',
    color: 'var(--gray)',
    transition: 'none',
    '&:hover': {
      backgroundColor: '#fff'
    },
    '&:active': {},
    '&$secondary': {
      color: 'var(--light-gray--)',
      background: 'none',
      border: '1px solid var(--light-gray--)',
      '&:hover': {
        background: 'rgba(255, 255, 255, .05)',
        color: 'var(--light-gray--)'
      }
    },
    '&$simple': {
      color: 'var(--light-gray--)',
      '&:hover': {
        color: 'var(--light-gray--)'
      },
      '&:active': {
        color: 'var(--light-gray--)'
      }
    }
  },
  purple: {
    border: `1px solid var(--purple)`,
    backgroundColor: 'var(--purple)',
    '&:hover': {
      backgroundColor: 'var(--purple-)'
    },
    '&:active': {
      boxShadow: `0 0 2px 1px var(--purple--)`
    },
    '&$secondary': {
      color: 'var(--purple)',
      '&:hover': {
        color: '#fff'
      }
    },
    '&$simple': {
      color: 'var(--purple)',
      '&:hover': {
        color: 'var(--purple-)'
      },
      '&:active': {
        color: 'var(--purple)'
      }
    }
  },
  secondary: {
    backgroundColor: '#fff',
    color: COLORS.BLUE.NORMAL,
    '&:hover': {
      color: '#fff'
    }
  },
  danger: {
    borderColor: COLORS.RED.FADED,
    backgroundColor: COLORS.RED.FADED,
    '&:hover': {
      backgroundColor: COLORS.RED.EXTRA_FADED
    },
    '&$simple': {
      color: COLORS.RED.FADED,
      '&:hover': {
        color: COLORS.RED.EXTRA_FADED
      },
      '&:active': {
        color: COLORS.RED.FADED
      }
    }
  },
  dangerSecondary: {
    borderColor: COLORS.RED.EXTRA_FADED,
    backgroundColor: '#fff',
    color: COLORS.RED.FADED,
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: COLORS.RED.NORMAL,
      color: COLORS.RED.NORMAL
    }
  },
  success: {
    borderColor: COLORS.GREEN.FADED,
    backgroundColor: COLORS.GREEN.FADED,
    '&:hover': {
      backgroundColor: COLORS.GREEN.EXTRA_FADED
    }
  },
  successSecondary: {
    borderColor: COLORS.GREEN.EXTRA_FADED,
    backgroundColor: '#fff',
    color: COLORS.GREEN.FADED,
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: COLORS.GREEN.NORMAL,
      color: COLORS.GREEN.NORMAL
    }
  },
  warning: {
    borderColor: COLORS.ALERT.WARNING.FADED,
    backgroundColor: COLORS.ALERT.WARNING.FADED,
    '&:hover': {
      backgroundColor: COLORS.ALERT.WARNING.EXTRA_FADED
    }
  },
  warningSecondary: {
    borderColor: COLORS.ALERT.WARNING.EXTRA_FADED,
    backgroundColor: '#fff',
    color: COLORS.ALERT.WARNING.FADED,
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: COLORS.ALERT.WARNING.NORMAL,
      color: COLORS.ALERT.WARNING.NORMAL
    }
  },
  disabled: {
    backgroundColor: '#f9f9f9',
    borderColor: '#dedede',
    color: '#cbcbcb',
    cursor: 'not-allowed',
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: '#f9f9f9',
      borderColor: '#dedede',
      color: '#cbcbcb'
    }
  },
  wide: {
    width: '100%',
    justifyContent: 'center'
  },
  alignLeft: {
    justifyContent: 'flex-start'
  },
  alignRight: {
    justifyContent: 'flex-end'
  },
  alignCenter: {
    justifyContent: 'center'
  },
  simple: {
    color: COLORS.BLUE.NORMAL,
    width: 'auto',
    background: 'none',
    border: 'none',
    '&:hover': {
      background: 'none',
      color: COLORS.BLUE.FADED
    },
    '&:active': {
      boxShadow: 'none',
      color: COLORS.BLUE.NORMAL,
      transition: 'none'
    },
    '&$disabled': {
      color: '#cbcbcb'
    },
    '&$dangerSecondary': {
      color: COLORS.RED.FADED,
      '&:hover': {
        color: COLORS.RED.NORMAL
      }
    }
  },
  tertiary: {
    color: 'var(--gray--)',
    width: 'auto',
    background: 'none',
    border: 'none',
    '&:hover': {
      background: 'none',
      color: 'var(--gray-)'
    },
    '&:active': {
      boxShadow: 'none',
      color: 'var(--gray-)',
      transition: 'none'
    },
    '&$disabled': {
      color: '#cbcbcb'
    }
  },
  bleed: {
    paddingRight: '0',
    paddingLeft: '0'
  }
}
