import THEME from 'pobble-components/dist/theme'

const { COLORS } = THEME

const styles = {
  environmentLine: {
    background: '#ccc',
    color: '#ccc',
    width: '100%',
    height: '7px',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: '1000',
    pointerEvents: 'none',
    fontWeight: 'bold',
    fontSize: '8px',
    textTransform: 'uppercase'
  },
  environmentLabel: {
    display: 'block',
    height: '100%',
    padding: '0 8px',
    background: '#fff',
    position: 'absolute',
    top: '0',
    left: '10px',
    lineHeight: '1'
  },
  stg: {
    background: COLORS.PURPLE.NORMAL,
    color: COLORS.PURPLE.NORMAL
  },
  prod: {
    background: COLORS.RED.NORMAL,
    color: COLORS.RED.NORMAL
  }
}

export default styles
