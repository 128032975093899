const styles = {
  container: {
    fontSize: '16px',
    color: '#666',
    borderBottom: '1px solid #ececec',
    padding: '20px',
    marginBottom: '10px'
  }
}

export default styles
