import React from 'react'
import injectSheet from 'react-jss'
import styles from './avatar.styles'
import classNames from 'classnames'
import { MyAvatar } from 'pobble-components/dist/pobble-avatar'

const Avatar = ({ classes, className, circles }) => (
  <div className={classNames(classes.avatar, className)}>
    <MyAvatar className={classes.pobbleAvatar} />
    {circles && (
      <div className={classes.profileCircles}>
        <div className={classes.profileCircle} />
        <div className={classes.profileCircle} />
        <div className={classes.profileCircle} />
        <div className={classes.profileCircle} />
      </div>
    )}
  </div>
)

export default injectSheet(styles)(Avatar)
