import React from 'react'
import { toast as toastify } from 'react-toastify'
import ToastContainer from './pobble-toast/pobble-toast'
import ToastComponent from './pobble-toast/toast-component/toast-component'

const showToast = (type, children) =>
  toastify(({ closeToast }) => (
    <ToastComponent closeToast={closeToast} type={type}>
      {children}
    </ToastComponent>
  ))

const toast = {
  success: children => showToast('success', children),
  info: children => showToast('info', children),
  warn: children => showToast('warning', children),
  error: children => showToast('error', children)
}

export { ToastContainer, toast }
