export default {
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    width: '0.6em',
    height: '0.6em',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  }
}
