import THEME from 'pobble-components/dist/theme'

const { COLORS } = THEME

const styles = {
  button: {
    display: 'inline-block'
  },
  tooltip: {
    display: 'inline-block',
    borderRadius: '8px',
    margin: '10px 0',
    maxWidth: '240px',
    zIndex: '11000',
    pointerEvents: 'none'
  },
  body: {
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    display: 'inline-block',
    backgroundColor: '#313131',
    color: '#fff',
    fontSize: '14px',
    lineHeight: '16px',
    padding: '8px 12px',
    animation: 'open .2s cubic-bezier(0,0,0,1)'
  },
  closing: {
    '& $body': {
      animation: 'close .2s cubic-bezier(0,0,0,1)'
    }
  },
  danger: {
    backgroundColor: COLORS.RED.NORMAL,
    '& $arrow': {
      color: COLORS.RED.NORMAL
    }
  },
  arrow: {
    position: 'absolute',
    width: '20px',
    height: '20px',
    bottom: '-12px',
    color: '#313131',
    '& svg': {
      width: '100%',
      height: '100%'
    }
  },
  arrowBottom: {
    bottom: 'auto',
    top: '-12px'
  },
  '@keyframes open': {
    from: {
      opacity: '0',
      transform: 'translateY(-5px)'
    },
    to: {
      opacity: '1',
      transform: 'translateY(0)'
    }
  },
  '@keyframes close': {
    from: {
      opacity: '1',
      transform: 'translateY(0)'
    },
    to: {
      opacity: '0',
      transform: 'translateY(-5px)'
    }
  }
}

export default styles
