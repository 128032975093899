import THEME from 'pobble-components/dist/theme'

const { COLORS } = THEME

const styles = {
  spinner: {
    fontSize: '40px',
    width: '1em',
    height: '1em',
    position: 'relative',
    animation: 'spCircRot .6s infinite linear',
    padding: '2px',
    color: COLORS.BLUE.NORMAL,
    '& > div': {
      position: 'relative',
      width: '100%',
      height: '100%',
      '&:after': {
        content: '""',
        border: '2px solid currentColor',
        position: 'absolute',
        left: '-2px',
        top: '-2px',
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        opacity: '.3',
        zIndex: '100'
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        left: '-2px',
        top: '-2px',
        width: '100%',
        height: '100%',
        border: '2px solid transparent',
        borderTop: '2px solid currentColor',
        borderRadius: '50%',
        zIndex: '110'
      }
    }
  },
  '@keyframes spCircRot': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(359deg)'
    }
  }
}

export default styles
