import React, { Fragment } from 'react'
import styles from './student-avatar.styles'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import { COLORS_MAP } from './colors-map'
import { ICONS_MAP } from './icons-map'

const StudentAvatar = ({ classes, codeRef, colorRef, className }) => {
  const color = COLORS_MAP[colorRef] || '#fff'
  const icon = ICONS_MAP[codeRef] || <Fragment />
  return (
    <div
      className={classNames(classes.container, className)}
      style={{ backgroundColor: color }}>
      {icon && (
        <div
          className={classes.icon}
          style={{ backgroundImage: `url("${icon}")` }}
        />
      )}
    </div>
  )
}

export default injectSheet(styles)(StudentAvatar)
