import React from 'react'
import injectSheet from 'react-jss'
import styles from './confirmation-dialog.styles'
import { Modal, ModalContent } from 'pobble-components/dist/modal'
import Button from 'pobble-components/dist/button'
import LineBreak from 'pobble-components/dist/line-break'
import classNames from 'classnames'

const ConfirmationDialogComponent = ({
  classes,
  isOpen,
  onClose,
  content,
  actionButtonText = 'Yes',
  cancelButtonText = 'Cancel',
  action,
  theme
}) => {
  return (
    <Modal
      isOpen={isOpen}
      requestClose={onClose}
      overlayClassName={classes.overlay}>
      <ModalContent
        legacy
        className={classNames(classes.content, {
          [classes.danger]: theme === 'danger'
        })}>
        <p className={classes.text}>
          <LineBreak>{content}</LineBreak>
        </p>
        <div className={classes.buttonHolder}>
          <Button
            className={classes.cancelButton}
            green={theme === 'green'}
            simple
            tall
            action={() => onClose()}
            type="button">
            {cancelButtonText}
          </Button>
          <Button
            danger={theme === 'danger'}
            alignCenter
            className={classes.actionButton}
            green={theme === 'green'}
            tall
            action={() => {
              action && action()
              onClose()
            }}
            type="button">
            {actionButtonText}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default injectSheet(styles)(ConfirmationDialogComponent)
