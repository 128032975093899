import React from 'react'
import styles from './head-cell.styles'
import injectSheet from 'react-jss'
import FaSortIcon from 'react-icons/lib/fa/sort'
import FaSortUpIcon from 'react-icons/lib/fa/sort-asc'
import FaSortDownIcon from 'react-icons/lib/fa/sort-desc'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const HeadCell = ({ classes, children, className, ordered, order }) => (
  <th className={classNames(classes.container, className)}>
    <span
      onClick={() =>
        order && order(!ordered ? 'asc' : ordered === 'asc' ? 'desc' : 'asc')
      }
      className={classNames({ [classes.withOrder]: order })}>
      {children}
      {order && (
        <span className={classes.icon}>
          {order && !ordered && <FaSortIcon />}
          {ordered === 'asc' && <FaSortDownIcon />}
          {ordered === 'desc' && <FaSortUpIcon />}
        </span>
      )}
    </span>
  </th>
)

HeadCell.propTypes = {
  /** Additional className for restyling purposes */
  className: PropTypes.string,
  /** Specify current order of the cell */
  ordered: PropTypes.oneOf(['asc', 'desc', false]),
  /** Order function */
  order: PropTypes.func
}

export default injectSheet(styles)(HeadCell)
