export default {
  container: ({ left, center, right }) => ({
    fontWeight: 'normal',
    color: '#555',
    padding: '20px 10px',
    whiteSpace: 'nowrap',
    textAlign: left ? 'left' : center ? 'center' : right ? 'right' : 'center'
  }),
  withOrder: {
    cursor: 'pointer',
    userSelect: 'none'
  },
  icon: {
    display: 'inline-block',
    width: '14px',
    height: '14px',
    verticalAlign: 'top',
    margin: '-2px 0 0 2px',
    '& svg': {
      width: '100%',
      height: '100%'
    }
  }
}
