import { fullVHExceptHeader } from 'pobble-components/dist/jss-mixins'

export default ({ FONT_SIZES }) => ({
  container: {
    ...FONT_SIZES.BODY_LARGE.STYLE,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    animation: '0.5s fadeIn',
    width: '100%',
    ...fullVHExceptHeader
  },
  holder: {
    maxWidth: '370px',
    width: '100vw',
    background: '#fff',
    padding: '40px',
    border: '1px solid #ececec',
    borderRadius: '10px'
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  }
})
