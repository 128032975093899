import React from 'react'
import Container from '../../components/container/container'
import Title from '../../components/title/title'
import Description from '../../components/description/description'
import Button from 'pobble-components/dist/button'
import { redirectToAuth } from 'pobble-components/dist/auth-helpers'

const FSUScreen = () => (
  <Container>
    <Title>Almost done!</Title>
    <Description>
      <p>
        We need a little more information to finish setting up your account.
      </p>
    </Description>
    <Button
      tall
      action={() =>
        redirectToAuth({ route: 'finish-signup', redirectBack: true })
      }>
      Complete your account
    </Button>
  </Container>
)

export default FSUScreen
