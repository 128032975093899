import React from 'react'
import { withMe } from 'pobble-components/dist/pobble-user'
import { PobbleAvatar } from 'pobble-components/dist/pobble-avatar'

const MyAvatar = ({ me, ...props }) => (
  <PobbleAvatar
    avatar={me.account.avatar}
    name={me.account.display_name}
    {...props}
  />
)

export default withMe(MyAvatar)
