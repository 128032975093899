import THEME from 'pobble-components/dist/theme'

const { COLORS } = THEME

const styles = {
  checkboxContainer: {
    position: 'relative',
    width: '20px',
    height: '20px',
    display: 'inline-block'
  },
  checkboxDisabled: {
    '& $checkboxBG': {
      backgroundColor: '#DFDFDF',
      borderColor: '#C9C9C9',
      cursor: 'default'
    },
    '& $checkbox': {
      cursor: 'default'
    },
    '& $mark': {
      backgroundColor: '#DFDFDF',
      borderColor: '#C9C9C9'
    }
  },
  checkbox: {
    width: '20px',
    height: '20px',
    border: 'none',
    opacity: '0',
    cursor: 'pointer',
    '&:checked + $checkboxBG': {
      borderColor: 'transparent',
      '& > span': {
        opacity: '1',
        transform: 'scale(1)'
      }
    }
  },
  checkboxBG: {
    position: 'absolute',
    pointerEvents: 'none',
    left: '0',
    top: '0',
    width: '20px',
    height: '20px',
    border: '1px solid #dedede',
    backgroundColor: '#fafafa',
    borderRadius: '4px',
    display: 'inline-block',
    cursor: 'pointer',
    '& *': {
      pointerEvents: 'none'
    }
  },
  mark: {
    display: 'block',
    transform: 'scale(0.1)',
    transition:
      'transform 350ms cubic-bezier(0.38, 0.57, 0.57, 1.62), opacity 250ms ease-in',
    opacity: '0',
    width: '20px',
    height: '20px',
    border: `1px solid ${COLORS.BLUE.NORMAL}`,
    backgroundColor: COLORS.BLUE.EXTRA_FADED,
    borderRadius: '4px',
    padding: '2px',
    margin: '-1px 0 0 -1px',
    '& svg': {
      color: '#fff',
      width: '14px'
    }
  }
}

export default styles
