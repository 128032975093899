import React from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import pageMonster from 'pobble-components/dist/full-page-error/page-monster.png'
import pageMonsterGuard from 'pobble-components/dist/full-page-error/page-monster-guard.png'
import pageMonsterUnplug from 'pobble-components/dist/full-page-error/page-monster-unplug.png'
import THEME from 'pobble-components/dist/theme'
import { fullVHExceptHeader } from 'pobble-components/dist/jss-mixins'

const { COLORS, FONT_SIZES } = THEME

const FullPageError = injectSheet({
  container: {
    ...fullVHExceptHeader,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  holder: {
    display: 'flex',
    alignItems: 'center',
    [`@media (max-width: 1023px)`]: {
      flexDirection: 'column'
    }
  },
  img: {
    width: '320px',
    height: 'auto',
    margin: '0 0 0 50px',
    [`@media (max-width: 1023px)`]: {
      margin: '0',
      order: '1',
      width: '250px',
      height: 'auto'
    }
  },
  descHolder: {
    color: COLORS.TEXT.DARK_GREY.NORMAL,
    ...FONT_SIZES.BODY_LARGE.STYLE,
    textAlign: 'center',
    maxWidth: '300px',
    [`@media (max-width: 1023px)`]: {
      order: '2'
    }
  },
  title: {
    color: COLORS.DARK_BLUE.NORMAL,
    fontWeight: 'bold',
    ...FONT_SIZES.HEADING_LARGE.STYLE,
    margin: '0 0 12px'
  },
  paragraph: {
    margin: '0 0 18px'
  },
  status: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    fontSize: '100px',
    fontWeight: 'bold',
    lineHeight: '1',
    margin: '0',
    color: '#ddd'
  }
})(({ classes, className, title, desc, button, status, monster }) => {
  let img = pageMonster

  if (monster === 'guard') {
    img = pageMonsterGuard
  }

  if (monster === 'unplug') {
    img = pageMonsterUnplug
  }

  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.holder}>
        <div className={classes.descHolder}>
          <h2 className={classes.title}>{title}</h2>
          <p className={classes.paragraph}>{desc}</p>
          {button}
          {status && <p className={classes.status}>{status}</p>}
        </div>
        {img && (
          <img className={classes.img} src={img} alt="Page monster Wally" />
        )}
      </div>
    </div>
  )
})

export default FullPageError
