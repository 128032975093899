import THEME from 'pobble-components/dist/theme'

const { COLORS, FONT_SIZES } = THEME

const style = {
  ageGroup: {
    textAlign: 'center',
    display: 'inline-block',
    padding: '6px 8px',
    border: '1px solid transparent',
    borderRadius: '4px',
    color: '#fff',
    width: '82px',
    whiteSpace: 'nowrap',
    ...FONT_SIZES.BODY_DEFAULT.STYLE
  },
  order0: {
    borderColor: COLORS.SUPPORTING.YEAR0.NORMAL,
    backgroundColor: COLORS.SUPPORTING.YEAR0.EXTRA_FADED
  },
  order1: {
    borderColor: COLORS.SUPPORTING.YEAR1.NORMAL,
    backgroundColor: COLORS.SUPPORTING.YEAR1.EXTRA_FADED
  },
  order2: {
    borderColor: COLORS.SUPPORTING.YEAR2.NORMAL,
    backgroundColor: COLORS.SUPPORTING.YEAR2.EXTRA_FADED
  },
  order3: {
    borderColor: COLORS.SUPPORTING.YEAR3.NORMAL,
    backgroundColor: COLORS.SUPPORTING.YEAR3.EXTRA_FADED
  },
  order4: {
    borderColor: COLORS.SUPPORTING.YEAR4.NORMAL,
    backgroundColor: COLORS.SUPPORTING.YEAR4.EXTRA_FADED
  },
  order5: {
    borderColor: COLORS.SUPPORTING.YEAR5.NORMAL,
    backgroundColor: COLORS.SUPPORTING.YEAR5.EXTRA_FADED
  },
  order6: {
    borderColor: COLORS.SUPPORTING.YEAR6.NORMAL,
    backgroundColor: COLORS.SUPPORTING.YEAR6.EXTRA_FADED
  },
  order7: {
    borderColor: COLORS.SUPPORTING.YEAR7.NORMAL,
    backgroundColor: COLORS.SUPPORTING.YEAR7.EXTRA_FADED
  },
  order8: {
    borderColor: COLORS.SUPPORTING.YEAR8.NORMAL,
    backgroundColor: COLORS.SUPPORTING.YEAR8.EXTRA_FADED
  },
  order9: {
    borderColor: COLORS.SUPPORTING.YEAR9.NORMAL,
    backgroundColor: COLORS.SUPPORTING.YEAR9.EXTRA_FADED
  },
  order10: {
    borderColor: COLORS.SUPPORTING.YEAR10.NORMAL,
    backgroundColor: COLORS.SUPPORTING.YEAR10.EXTRA_FADED
  },
  order11: {
    borderColor: COLORS.SUPPORTING.YEAR11.NORMAL,
    backgroundColor: COLORS.SUPPORTING.YEAR11.EXTRA_FADED
  },
  order12: {
    borderColor: COLORS.SUPPORTING.YEAR12.NORMAL,
    backgroundColor: COLORS.SUPPORTING.YEAR12.EXTRA_FADED
  },
  order13: {
    borderColor: COLORS.SUPPORTING.YEAR13.NORMAL,
    backgroundColor: COLORS.SUPPORTING.YEAR13.EXTRA_FADED
  },
  order14: {
    borderColor: COLORS.SUPPORTING.YEAR14.NORMAL,
    backgroundColor: COLORS.SUPPORTING.YEAR14.EXTRA_FADED
  },
  order15: {
    borderColor: COLORS.RED.FADED,
    backgroundColor: COLORS.RED.EXTRA_FADED
  },
  notAssigned: {
    backgroundColor: '#cacaca',
    borderColor: '#a7a7a7'
  },
  naIcon: {
    fontSize: '.8em',
    marginRight: '.4em'
  }
}

export default style
