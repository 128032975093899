export default {
  avatar: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid transparent',
    borderRadius: '100%',
    lineHeight: '1',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '0.5em'
  },
  purple: {
    backgroundColor: '#B4A0CA',
    borderColor: '#805FA5'
  },
  green: {
    backgroundColor: '#CEEA50',
    borderColor: '#9FBE17'
  },
  blue: {
    backgroundColor: '#55D1FF',
    borderColor: '#00AFEC'
  },
  red: {
    backgroundColor: '#F2987F',
    borderColor: '#E94D23'
  },
  yellow: {
    backgroundColor: '#FFF199',
    borderColor: '#FEE744'
  },
  darkBlue: {
    backgroundColor: '#4A6CD1',
    borderColor: '#25408F'
  }
}
