const openConfirmationDialog = ({
  bodyText,
  confirmText,
  cancelText,
  action,
  theme
}) => ({
  type: 'CONFIRMATION_DIALOG:OPEN',
  bodyText,
  confirmText,
  cancelText,
  action,
  theme
})

const closeConfirmationDialog = () => ({
  type: 'CONFIRMATION_DIALOG:CLOSE'
})

export { openConfirmationDialog, closeConfirmationDialog }
