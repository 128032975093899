export default {
  container: {
    margin: '0 0 30px',
    '& a': {
      color: 'currentColor'
    },
    '& > p': {
      margin: '15px 0 0',
      '&:first-child': {
        margin: '0'
      }
    }
  }
}
