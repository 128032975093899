import React, { Component } from 'react'
import injectSheet from 'react-jss'
import ActivityAction from './activity-action/activity-action.jsx'
import styles from './activity-item.styles.js'
import { PobbleAvatar } from 'pobble-components/dist/pobble-avatar'
import distanceInWordsStrict from 'date-fns/distance_in_words_strict'
import { apiMarkActivityAsRead } from '../../../common/api/activity.js'

class ActivityItem extends Component {
  links(type) {
    const { activity } = this.props
    const urls = {
      CommentApproved: `/works/${activity.activity_object.id}`,
      WorkPublished: `/works/${activity.activity_object.id}`,
      SchoolFollowed: `/schools/${activity.activity_object.id}`,
      SchoolUnfollowed: `/schools/${activity.activity_object.id}`,
      PresentationShared: `/lessons/shared/${activity.activity_object.id}`,
      ParentAddedToStudent: `/student/${activity.activity_object.id}`,
      GuestModeratorInvited: `/moderation/assessment-files/${activity
        .activity_object.assessment_file &&
        activity.activity_object.assessment_file.id}`,
      ContributionMarkedAsConclusion: `/moderation/assessment-files/${activity
        .activity_object.assessment_file &&
        activity.activity_object.assessment_file.id}`,
      WorkSubmittedByStudent: `/actions/`,
      PaPresentationActionCreated: `/pupil/`,
      WorkApprovedByTeacher: `/pupil/`,
      WorkReturnedByTeacher: `/pupil/`,
      WorkSentToPeer: `/pupil/`,
      WorkSentBackFromPeer: `/pupil/`
    }
    return urls[type]
  }

  toActivity(id, type) {
    const url = this.links(type)
    apiMarkActivityAsRead(id).then(() => (window.location = url))
  }

  render() {
    const { classes, activity } = this.props
    return (
      activity && (
        <a
          href={this.links(activity.type)}
          className={classes.container}
          onClick={() => {
            window.posthog?.capture('click on a single activity item', {
              type: activity.activity_type
            })
            this.toActivity(activity.id, activity.activity_type)
          }}>
          <div className={classes.action}>
            <PobbleAvatar
              avatar={activity.originator.avatar}
              name={activity.originator.display_name}
              className={classes.avatar}
            />
            <em>
              <ActivityAction activity={activity} />
            </em>
          </div>
          <div className={classes.timestamp}>
            {distanceInWordsStrict(new Date(), new Date(activity.occurred_at))}
          </div>
        </a>
      )
    )
  }
}

export default injectSheet(styles)(ActivityItem)
