import React from 'react'
import styles from './form-file.styles'
import injectSheet from 'react-jss'
import uuid from 'uuid/v4'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const FormFile = ({
  classes,
  id = uuid(),
  children,
  onChange,
  style,
  className,
  inputProps,
  ...props
}) => (
  <div style={style} className={classNames(classes.container, className)}>
    <input
      value={''}
      onChange={e =>
        e.currentTarget.files.length &&
        onChange &&
        onChange([...e.currentTarget.files], e)
      }
      className={classes.input}
      type="file"
      id={id}
      {...inputProps}
      {...props}
    />
    <label htmlFor={id}>{children}</label>
  </div>
)

FormFile.propTypes = {
  /** Id of the input DOM node (will be created randomly if not provided) */
  id: PropTypes.string,
  /** Whatever you put inside component will serve as trigger */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  /** Additional className if you want to restyle component */
  className: PropTypes.string,
  /** Object with all additional input props you want to pass. Check official input documentation to get the idea what props os available */
  inputProps: PropTypes.object
}

export default injectSheet(styles)(FormFile)
