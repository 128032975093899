import { ThemeProvider } from 'react-jss'
import THEME from 'pobble-components/dist/theme'
import injectSheet from 'react-jss/lib/injectSheet'
import React from 'react'

export const CSSVars = injectSheet({
  '@global': {
    ':root': {
      '--blue': '#00afec',
      '--blue-': '#54bef2',
      '--blue--': '#9ad7f7',
      '--green': '#a0be17',
      '--green-': '#b3ca54',
      '--green--': '#d0df98',
      '--darkBlue': '#25408f',
      '--darkBlue-': '#5165a5',
      '--darkBlue--': '#92a0c8',
      '--red': '#e94d23',
      '--red-': '#ef7354',
      '--red--': '#f7a996',
      '--purple': '#815fa5',
      '--purple-': '#9a7fb8',
      '--purple--': '#c1b0d2',
      '--yellow': '#fee433',
      '--yellow-': '#fee966',
      '--yellow--': '#fff2a3',
      '--gray': '#333333',
      '--gray-': '#555555',
      '--gray--': '#777777',
      '--gray---': '#999999',
      '--lightGray': '#ececec',
      '--lightGray-': '#f0f0f0',
      '--lightGray--': '#f6f6f6',
      '--bluegray': 'hsl(196deg 100% 3%)',
      '--bluegray-': 'hsl(196deg 30% 9%)',
      '--bluegray--': 'hsl(196deg 20% 16%)',
      '--bluegray---': 'hsl(196deg 26% 24%)',
      '--body': `16px/20px ${THEME.FONTS.MAIN}`,
      '--body-': `14px/17px ${THEME.FONTS.MAIN}`,
      '--body--': `12px/15px ${THEME.FONTS.MAIN}`,
      '--body---': `10px/12px ${THEME.FONTS.MAIN}`,
      '--heading_': `bold 34px/38px ${THEME.FONTS.MAIN}`,
      '--heading': `bold 26px/30px ${THEME.FONTS.MAIN}`,
      '--heading-': `bold 22px/26px ${THEME.FONTS.MAIN}`,
      '--heading--': `bold 18px/20px ${THEME.FONTS.MAIN}`,
      '--heading---': `bold 16px/20px ${THEME.FONTS.MAIN}`
    }
  }
})(({ children }) => children)

export const GlobalStyles = injectSheet({
  '@global': {
    body: {
      '-webkit-font-smoothing': 'antialiased',
      backgroundColor: '#F8F8F8',
      font: `${THEME.FONT_SIZES.BODY_DEFAULT.COMBINATION} ${THEME.FONTS.MAIN}`,
      color: THEME.COLORS.TEXT.DARK_GREY.NORMAL
    },
    '*': {
      boxSizing: 'border-box',
      '&:focus': {
        outline: 'none'
      }
    },
    h1: THEME.FONT_SIZES.HEADING_EXTRA_LARGE.STYLE,
    h2: THEME.FONT_SIZES.HEADING_LARGE.STYLE,
    h3: THEME.FONT_SIZES.HEADING_BIG.STYLE,
    h4: THEME.FONT_SIZES.HEADING_MEDIUM.STYLE,
    h5: THEME.FONT_SIZES.HEADING_DEFAULT.STYLE,
    h6: THEME.FONT_SIZES.HEADING_SMALL.STYLE,
    'input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '.h-screen-header': {
      height: 'calc(100vh - 70px)',
      '@media (max-width: 768px)': {
        height: 'calc(100vh - 50px)'
      }
    }
  }
})(({ children }) => children)

export const PobbleStyles = ({ children, noGlobalStyles }) => {
  return (
    <ThemeProvider theme={THEME}>
      <CSSVars>
        {noGlobalStyles ? children : <GlobalStyles>{children}</GlobalStyles>}
      </CSSVars>
    </ThemeProvider>
  )
}
