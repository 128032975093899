import React from 'react'
import Pagination from './pagination'

const PaginationContainer = ({ data, ...props }) => {
  if (!data) return null
  return (
    <Pagination currentPage={data.current} totalPages={data.last} {...props} />
  )
}

export default PaginationContainer
