import React from 'react'
import injectSheet from 'react-jss'
import styles from './environment-line.styles.js'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { DOMAIN_PREFIX } from 'pobble-components/dist/setup'

const EnvironmentLine = ({ classes, className, prefix = DOMAIN_PREFIX }) => {
  const visible = prefix === 'stg'
  return !visible ? null : (
    <div
      className={classNames(
        classes.environmentLine,
        {
          [classes.stg]: prefix === 'stg'
        },
        className
      )}>
      <span className={classes.environmentLabel}>{prefix}</span>
    </div>
  )
}

EnvironmentLine.propTypes = {
  /** A given string of the current environment prefix (ex: `stg`) */
  prefix: PropTypes.string,
  /** Additional className if you want to restyle component */
  className: PropTypes.string
}

export default injectSheet(styles)(EnvironmentLine)
