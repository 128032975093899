export default {
  modal: {
    zIndex: '1000'
  },
  overlay: {
    zIndex: '9999'
  },
  content: {
    maxWidth: '732px',
    padding: '50px 38px 40px 38px',
    '@media (min-width: 451px)': {
      padding: '40px'
    },
    '@media (min-width: 769px)': {
      padding: '60px',
      maxWidth: '880px'
    }
  }
}
