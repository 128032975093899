import React, { Component } from 'react'
import styles from './initials-avatar.styles'
import injectSheet from 'react-jss'
import classNames from 'classnames'

class InitialsAvatar extends Component {
  getColorClassName(letter) {
    const { classes } = this.props
    const charCode = letter.charCodeAt(0)
    if (charCode < 65 || charCode > 90) return classes.red
    if (charCode >= 65 && charCode <= 68) return classes.purple
    if (charCode >= 69 && charCode <= 72) return classes.green
    if (charCode >= 73 && charCode <= 76) return classes.blue
    if (charCode >= 77 && charCode <= 80) return classes.red
    if (charCode >= 81 && charCode <= 84) return classes.yellow
    if (charCode >= 85 && charCode <= 90) return classes.darkBlue
  }
  render() {
    const { name, classes, className } = this.props
    const letter = name.charAt(0).toUpperCase()
    const colorClassName = this.getColorClassName(letter)
    return (
      <span className={classNames(classes.avatar, colorClassName, className)}>
        {letter}
      </span>
    )
  }
}

export default injectSheet(styles)(InitialsAvatar)
