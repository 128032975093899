import React, { Fragment } from 'react'
import styles from './school-required-screen.styles'
import injectSheet from 'react-jss'
import Button from 'pobble-components/dist/button'
import FullpageError from 'pobble-components/dist/full-page-error'
import { URLS } from 'pobble-components/dist/setup'

const SchoolRequiredScreen = () => (
  <FullpageError
    monster="guard"
    title={
      <Fragment>
        You need to be
        <br />
        part of a school to access this feature
      </Fragment>
    }
    desc={
      <Fragment>
        You can request access
        <br />
        on your profile page
      </Fragment>
    }
    button={
      <Button tall href={URLS.ACCOUNT}>
        Go to profile page
      </Button>
    }
  />
)

export default injectSheet(styles)(SchoolRequiredScreen)
