import { apiClient } from 'pobble-components/dist/api'

const apiGetActivity = () => apiClient.get('v3/me/activity_feed_items/pending')

const apiMarkActivityAsRead = id =>
  apiClient.post(`v3/me/activity_feed_items/${id}/mark_read`)

const apiMarkAllActivitiesAsRead = () =>
  apiClient.post(`v3/me/activity_feed_items/mark_feed_read`)

export { apiGetActivity, apiMarkActivityAsRead, apiMarkAllActivitiesAsRead }
