import React from 'react'
import injectSheet from 'react-jss'
import styles from './user-name.styles.js'
import PropTypes from 'prop-types'
import UserAvatar from 'pobble-components/dist/user-avatar'
import classNames from 'classnames'

const UserName = ({ classes, children, className }) => (
  <span className={classNames(classes.container, className)}>
    <UserAvatar className={classes.userAvatar} name={children} />{' '}
    <span>{children}</span>
  </span>
)

UserName.propTypes = {
  /** The name of the Pupil */
  children: PropTypes.string
}

export default injectSheet(styles)(UserName)
