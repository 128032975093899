import { useContext } from 'react'
import PobbleUserContext from './context'

const useMe = () => {
  const {
    me,
    refetchMe,
    request,
    updateMe,
    showSchoolRequiredDialog
  } = useContext(PobbleUserContext)
  return {
    me,
    refetchMe,
    meRequest: request,
    updateMe,
    showSchoolRequiredDialog
  }
}

export default useMe
