import React from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'

const HeaderMenuBox = injectSheet({
  container: {
    '@media (min-width: 769px)': {
      padding: '26px',
      borderTop: '1px solid #ececec',
      '&:first-child': {
        borderTop: 'none'
      }
    }
  },
  title: {
    margin: '0',
    color: '#777',
    fontSize: '14px',
    lineHeight: '14px'
  },
  menu: {
    listStyle: 'none',
    padding: '0',
    margin: '0'
  }
})(({ classes, title, children, className }) => (
  <div className={classNames(classes.container, className)}>
    {title && <h5 className={classes.title}>{title}</h5>}
    <ul className={classes.menu}>{children}</ul>
  </div>
))

export default HeaderMenuBox
