import React from 'react'
import injectSheet from 'react-jss'
import styles from './activity-header.styles.js'

const ActivityHeader = ({ classes, count }) => (
  <div className={classes.container}>
    {count} new notification
    {count > 1 ? 's' : ''}
  </div>
)

export default injectSheet(styles)(ActivityHeader)
