import React from 'react'
import styles from './table-row.styles'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const TableRow = ({ classes, className, pending, children }) => (
  <tr
    className={classNames(classes.container, className, {
      [classes.pending]: pending
    })}>
    {children}
  </tr>
)

TableRow.propTypes = {
  /** Table content */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /** Greying out row, removing pointer events */
  pending: PropTypes.bool
}

export default injectSheet(styles)(TableRow)
