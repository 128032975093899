import { getDomainPrefix, getPobbleEnv } from 'pobble-components/dist/utils'

const CONSTANTS = {
  SENTRY_DSN_KEY: 'https://d1c5e09fda864c328fc685d56c26091b@sentry.io/1473700',
  MIXPANEL_TOKEN: {
    TEST: 'a7a150acefada72fb9d138ce91741660',
    PROD: '5e12fddb59f9ddeaee239702b2832def'
  },
  GTM_CONTAINER_ID: 'GTM-TBS6JPQ'
}

const DOMAIN_PREFIX = getDomainPrefix()
const ENV = getPobbleEnv()

const COOKIE_SETTINGS =
  ENV === 'local'
    ? null
    : {
        domain: '.pobble.com'
      }

const URL_PREFIX = DOMAIN_PREFIX ? DOMAIN_PREFIX + '-' : ''

const APP = `https://${URL_PREFIX}app.pobble.com`
const ADMIN = `https://${URL_PREFIX}admin.pobble.com`
const MARKETING = `https://${URL_PREFIX}www.pobble.com`
const MY_POBBLE = `https://my.pobble.com/en-gb`
const HELP = `https://help.pobble.com`
const BLOG = `https://blog.pobble.com`

const URLS = {
  APP,
  AUTH: `${APP}/auth`,
  ACCOUNT: `${APP}/account`,
  MEMBERSHIP: `${APP}/account/membership`,
  ADMIN,
  MARKETING,
  PUPIL_AUTH: `${APP}/pupil-auth`,
  LESSONS: `${APP}/lessons`,
  DISCOVER_LESSONS: `${APP}/lessons/discover`,
  DASHBOARD: `${APP}/dashboard`,
  ACTIONS: `${APP}/actions`,
  MODERATION: `${APP}/moderation`,
  PUPIL_ACTIVITIES: `${APP}/pupil`,
  MY_POBBLE,
  HELP,
  BLOG
}

export { CONSTANTS, DOMAIN_PREFIX, URLS, COOKIE_SETTINGS, ENV }
