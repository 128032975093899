export default {
  container: {
    borderRadius: '8px 8px 0 0',
    padding: '24px 20px',
    color: 'var(--gray-)',
    fontSize: '18px',
    lineHeight: '18px',
    backgroundColor: 'var(--light-gray--)'
  }
}
