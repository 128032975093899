import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './validation-error.styles'
import classNames from 'classnames'
import Tooltip from 'pobble-components/dist/tooltip'
import ExclamationIcon from 'react-icons/lib/fa/exclamation-circle'

class ValidationError extends Component {
  render() {
    const { classes, className, error, style } = this.props
    return (
      <Tooltip
        style={style}
        className={classNames(classes.validationError, className)}
        content={error}>
        <ExclamationIcon />
      </Tooltip>
    )
  }
}

export default injectSheet(styles)(ValidationError)
