import { useContext } from 'react'
import { useMe } from 'pobble-components/dist/pobble-user'
import { SubscriptionContext } from 'pobble-components/dist/subscriptions'
import { apiClient } from 'pobble-components/dist/api'
import { errorHandler } from 'pobble-components/dist/error-handler'

export const useSubscriptionDialog = () => {
  const context = useContext(SubscriptionContext)
  const { setSubsDialogState } = context
  return {
    showSubscriptionDialog: options =>
      setSubsDialogState({ open: true, ...options })
  }
}

export const useSubscriptionPolicy = check => {
  const { me } = useMe()
  const context = useContext(SubscriptionContext)
  if (!context) return { restricted: false }
  const { setSubsDialogState } = context
  const policy = check(me.account.subscription_policy)
  return {
    restricted: policy.restricted,
    showDialog: options => setSubsDialogState({ open: true, ...options })
  }
}

/**
 * Subscription policy and feature counters are tightly coupled
 *  whenever particular feature counter reaches the limit
 *  we need to update a subscription policy
 */
export const useFeatureCounter = (
  featureCounterName,
  subscriptionPolicySelector
) => {
  const { updateMe } = useMe()

  const sp = useSubscriptionPolicy(subscriptionPolicySelector)

  const featureCounter = () =>
    apiClient
      .post(`v3/me/subscriptions/increment_counter`, {
        counter_type: featureCounterName
      })
      .then(res => {
        if (res.data.limit_just_reached) {
          /**
           * When feature counter reaches the limit we need to update
           *  subscription policy on the user level
           */
          updateMe(me => {
            const meCopy = {
              ...me,
              account: {
                ...me.account,
                subscription_policy: {
                  ...me.account.subscription_policy
                }
              }
            }
            subscriptionPolicySelector(
              meCopy.account.subscription_policy
            ).restricted = true
            return meCopy
          })
        }
      })
      .catch(errorHandler)

  return [featureCounter, sp]
}

export const SubscriptionPolicy = ({ children, policy }) => {
  const sp = useSubscriptionPolicy(policy)
  return children(sp)
}
