import React from 'react'
import injectSheet from 'react-jss'
import {
  ToastContainer as ToastifyContainer,
  cssTransition
} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const styles = {
  '@keyframes enterAnimation': {
    from: { opacity: 0, transform: 'translate3d(0, 10px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' }
  },
  '@keyframes leaveAnimation': {
    from: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    to: { opacity: 0, transform: 'translate3d(-10px, 0, 0)' }
  },
  toast: {
    '& .Toastify__toast--default': {
      minHeight: 'auto',
      background: 'transparent',
      boxShadow: 'none'
    },
    '& .Toastify__toast': {
      borderRadius: '0',
      padding: '0',
      overflow: 'visible'
    },
    '& .Toastify__close-button': {
      display: 'none'
    },
    '& .Toastify__toast-body': {
      margin: '0'
    }
  },
  enter: {
    animationName: 'enterAnimation'
  },
  leave: {
    animationName: 'leaveAnimation'
  }
}

const ToastContainer = ({ classes, toastifyProps }) => (
  <ToastifyContainer
    autoClose={6000}
    position="bottom-left"
    hideProgressBar={true}
    className={classes.toast}
    draggable={false}
    closeOnClick={false}
    transition={cssTransition({
      enter: classes.enter,
      exit: classes.leave,
      duration: [300, 300],
      appendPosition: false
    })}
    {...toastifyProps}
  />
)

export default injectSheet(styles)(ToastContainer)
