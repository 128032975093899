import { useRef, useEffect } from 'react'

export const getPobbleDomain = (href = window.location.href) => {
  const regex = /(\.pobble\.com|pobble\.co\.uk)/
  const match = href.match(regex)
  if (!match) return null
  return match[0].slice(1, match[0].length)
}

export const getDomainPrefix = (href = window.location.href) => {
  const prefixMatch = href.match(
    /^(https:\/\/([a-z]+)-)(?=([a-z]+\.pobble\.(com|co\.uk)))/g
  )
  if (!prefixMatch) return ''
  const match = prefixMatch[0]
  return !match ? '' : match.split('https://')[1].slice(0, -1)
}

export const getPobbleEnv = (href = window.location.href) => {
  const localhostMatch = href.match(
    /(http:\/\/(localhost|127\.0\.0\.1|192\.168.{1,}:30.{2}))/
  )
  if (localhostMatch) return 'local'
  const isPobble = !!getPobbleDomain()
  if (!isPobble) return null
  const domainPrefix = getDomainPrefix(href)
  if (!domainPrefix || domainPrefix === 'prod') return 'prod'
  if (domainPrefix === 'stg') return 'stg'
}

export const getErrorInfo = e => {
  if (!e || !e.response)
    return {
      status: null,
      ref: null,
      msg: null
    }

  const info = {
    status: e.response.status
  }
  const errorDetails =
    !e.response.data ||
    !e.response.data.errors ||
    !e.response.data.errors.length
      ? { ref: null, msg: null }
      : {
          ref: e.response.data.errors[0].err || null,
          msg: e.response.data.errors[0].msg || null
        }
  return {
    ...info,
    ...errorDetails
  }
}

export const isIOS =
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

export const isTouchScreen =
  'ontouchstart' in window || !!navigator.msMaxTouchPoints

export const usePrevious = value => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
