import * as React from 'react'
const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}>
    <path
      fill="#25408F"
      d="M8.833 11.5 7.042 9.708a.79.79 0 0 0-.584-.229.79.79 0 0 0-.583.23.79.79 0 0 0-.23.583c0 .236.077.43.23.583L8.25 13.25a.8.8 0 0 0 .583.25.8.8 0 0 0 .584-.25l4.708-4.708a.79.79 0 0 0 .23-.584.79.79 0 0 0-.23-.583.79.79 0 0 0-.583-.23.79.79 0 0 0-.584.23L8.833 11.5ZM10 18.333a8.111 8.111 0 0 1-3.25-.656 8.428 8.428 0 0 1-2.646-1.781 8.41 8.41 0 0 1-1.78-2.646A8.13 8.13 0 0 1 1.666 10a8.11 8.11 0 0 1 .656-3.25 8.428 8.428 0 0 1 1.781-2.646 8.41 8.41 0 0 1 2.646-1.78A8.13 8.13 0 0 1 10 1.666a8.11 8.11 0 0 1 3.25.656 8.428 8.428 0 0 1 2.646 1.781 8.422 8.422 0 0 1 1.782 2.646 8.099 8.099 0 0 1 .655 3.25 8.11 8.11 0 0 1-.656 3.25 8.426 8.426 0 0 1-1.781 2.646 8.421 8.421 0 0 1-2.646 1.781 8.099 8.099 0 0 1-3.25.656Z"
    />
  </svg>
)
export default SvgComponent
