import { getPobbleEnv } from 'pobble-components/dist/utils'

const env = getPobbleEnv()

/**
 * Since we load all external tools lazily
 *  every method in this file has a fallback to queue
 *  in case if the tool hasn't loaded yet.
 *
 * When the tool is loaded it picks up a queue and executes it
 */

export const gtmDataLayerQueue = []
export const gtmDataLayer = dataLayer => {
  const TagManager =
    window.POBBLE_EXTERNAL_TOOLS && window.POBBLE_EXTERNAL_TOOLS.gtm
  if (!TagManager) {
    gtmDataLayerQueue.push(dataLayer)
  } else {
    if (dataLayer.userId) dataLayer.userId = `account_${dataLayer.userId}`
    TagManager.dataLayer({ dataLayer })
    env !== 'prod' && console.info('Metrics: GTM data layer', dataLayer)
  }
}
