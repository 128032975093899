import React, { useEffect, useState } from 'react'
import injectSheet from 'react-jss'
import NavItem from '../nav-item/nav-item.jsx'
import DropMenu from '../drop-menu/drop-menu.jsx'
import ActivityHeader from './activity-header/activity-header.jsx'
import ActivityItem from './activity-item/activity-item.jsx'
import classNames from 'classnames'
import {
  apiGetActivity,
  apiMarkAllActivitiesAsRead
} from '../../common/api/activity.js'
import Button from 'pobble-components/dist/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell'

const Activity = injectSheet({
  activityMenu: {
    display: 'none',
    '@media (min-width: 769px)': {
      display: 'block',
      width: '360px',
      marginRight: '5px',
      paddingBottom: '12px'
    }
  },
  list: {
    listStyle: 'none',
    padding: '0',
    margin: '0',
    display: 'flex',
    '& li': {
      '@media (max-width: 769px)': {
        padding: '0',
        border: 'none'
      }
    }
  },
  dot: {
    '@media (min-width: 769px)': {
      position: 'relative',
      margin: '0 10px 0 -10px',
      '& > a': {
        width: '46px'
      }
    },
    '& svg': {
      fontSize: '16px'
    },
    '& span:last-child': {
      '@media (min-width: 769px)': {
        display: 'none'
      }
    },
    '&:after': {
      content: '""',
      width: '8px',
      height: '8px',
      display: 'inline-block',
      background: 'var(--red)',
      position: 'absolute',
      top: '22px',
      left: '-6px',
      borderRadius: '100%',
      transform: 'scale(0)',
      '@media (min-width: 769px)': {
        top: '22px',
        left: '26px'
      }
    }
  },
  dotActive: {
    '&:after': {
      animation: '600ms cubic-bezier(0,.67,.42,2) scaleIn forwards'
    }
  },
  footer: {
    fontSize: '16px',
    color: '#a8a8a8',
    borderTop: '1px solid #ececec',
    marginTop: '10px',
    textAlign: 'center',
    '& > button': {
      padding: '32px 20px',
      width: '100%',
      textDecoration: 'none',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      color: '#a8a8a8',
      transition: 'color 300ms ease-in-out',
      '&:hover': {
        color: 'var(--blue)'
      }
    }
  },
  '@keyframes scaleIn': {
    from: { tranform: 'scale(0)' },
    to: { transform: 'scale(1)' }
  }
})(({ classes, activePage, appUrl }) => {
  const [data, setData] = useState([])

  const activities =
    data &&
    data
      .slice(0, 3)
      .map((activity, index) => (
        <ActivityItem key={index} activity={activity} />
      ))

  const count = data && data.length

  useEffect(() => {
    apiGetActivity().then(({ data }) => setData(data.activity_feed_items))
  }, [])

  const toActivities = e => {
    e && e.preventDefault()
    apiMarkAllActivitiesAsRead().then(() => (window.location = '/activity'))
  }

  return (
    <DropMenu className={classes.container}>
      {(Container, Menu) => (
        <Container>
          <ul className={classes.list}>
            <NavItem
              active={activePage === 'activity'}
              url={`${appUrl}/activity/`}
              icon={<FontAwesomeIcon icon={faBell} />}
              className={classNames(classes.dot, {
                [classes.dotActive]: !!count
              })}
              onClick={toActivities}>
              Activity
            </NavItem>
          </ul>
          {!!count && (
            <Menu className={classes.activityMenu}>
              <ActivityHeader count={count} />
              <div>{activities}</div>
              {count > 3 && (
                <div className={classes.footer}>
                  <Button
                    simple
                    action={() => {
                      window.posthog?.capture('click on view all activities')
                      toActivities()
                    }}>
                    View all
                  </Button>
                </div>
              )}
            </Menu>
          )}
        </Container>
      )}
    </DropMenu>
  )
})

export default Activity
