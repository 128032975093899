import { fullVHExceptHeader } from 'pobble-components/dist/jss-mixins'

export default {
  subscriptionRequiredHolder: {
    ...fullVHExceptHeader,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px',
    '& > *': {
      maxWidth: '820px'
    }
  }
}
