import { hexToRgbA } from './helpers'

class PobbleColor {
  constructor(color) {
    this.NORMAL = hexToRgbA(color)
    this.FADED = hexToRgbA(color, 0.8)
    this.EXTRA_FADED = hexToRgbA(color, 0.5)
  }
}

class PobbleFontSize {
  constructor(fontSize, lineHeight) {
    this.FONT_SIZE = fontSize + 'px'
    this.LINE_HEIGHT = lineHeight + 'px'
    this.COMBINATION = `${this.FONT_SIZE}/${this.LINE_HEIGHT}`
    this.STYLE = {
      fontSize: this.FONT_SIZE,
      lineHeight: this.LINE_HEIGHT
    }
  }
}

export default {
  COLORS: {
    BLUE: new PobbleColor('#00AFEC'),
    GREEN: new PobbleColor('#A0BE17'),
    DARK_BLUE: new PobbleColor('#25408F'),
    RED: new PobbleColor('#E94D23'),
    PURPLE: new PobbleColor('#815FA5'),
    YELLOW: new PobbleColor('#FEE433'),
    ALERT: {
      SUCCESS: new PobbleColor('#6F9914'),
      INFO: new PobbleColor('#00AFEC'),
      WARNING: new PobbleColor('#EB9B00'),
      ERROR: new PobbleColor('#DB3816')
    },
    SUPPORTING: {
      YEAR0: new PobbleColor('#bfaf64'),
      YEAR1: new PobbleColor('#add848'),
      YEAR2: new PobbleColor('#59d025'),
      YEAR3: new PobbleColor('#1ccebb'),
      YEAR4: new PobbleColor('#40abe8'),
      YEAR5: new PobbleColor('#1200ff'),
      YEAR6: new PobbleColor('#b035d3'),
      YEAR7: new PobbleColor('#d33565'),
      YEAR8: new PobbleColor('#ea0000'),
      YEAR9: new PobbleColor('#ff7e00'),
      YEAR10: new PobbleColor('#ffc000'),
      YEAR11: new PobbleColor('#8c7e34'),
      YEAR12: new PobbleColor('#3a2f0e'),
      YEAR13: new PobbleColor('#a2a093'),
      YEAR14: new PobbleColor('#44cba3')
    },
    TEXT: {
      OFF_BLACK: new PobbleColor('#333333'),
      DARK_GREY: new PobbleColor('#555555'),
      MID_GREY: new PobbleColor('#777777'),
      LIGHT_GREY: new PobbleColor('#999999')
    },
    BORDERS: {
      PRIMARY: new PobbleColor('#ECECEC')
    }
  },
  FONTS: {
    MAIN: '"proxima-nova-alt", Helvetica, Arial, sans-serif'
  },
  FONT_SIZES: {
    BODY_SMALL: new PobbleFontSize(12, 15),
    BODY_DEFAULT: new PobbleFontSize(14, 17),
    BODY_LARGE: new PobbleFontSize(16, 20),
    HEADING_NANO: new PobbleFontSize(10, 12),
    HEADING_TINY: new PobbleFontSize(12, 15),
    HEADING_SMALL: new PobbleFontSize(14, 17),
    HEADING_DEFAULT: new PobbleFontSize(16, 20),
    HEADING_MEDIUM: new PobbleFontSize(18, 22),
    HEADING_BIG: new PobbleFontSize(22, 26),
    HEADING_LARGE: new PobbleFontSize(26, 30),
    HEADING_EXTRA_LARGE: new PobbleFontSize(34, 38)
  },
  ICONS_SIZES: {
    SMALL: '14px',
    MEDIUM: '16px',
    LARGE: '20px'
  },
  BREAKPOINTS: {
    XL: '1600px',
    LG: '1199px',
    MD: '991px',
    SM: '768px',
    XS: '575px'
  }
}
