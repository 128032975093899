import getClient from 'pobble-components/dist/api-client'
import Cookies from 'js-cookie'

const DEV_API_URL = Cookies.get('dev_api_url')

const ENV = process.env.REACT_APP_ENV

if (!ENV && !DEV_API_URL) {
  Cookies.set(
    'dev_api_url',
    `https://${window.prompt('API url (without HTTPS)')}`
  )
  window.location.reload()
}

const API_ROOT = ENV
  ? `https://${ENV === 'stg' ? `stg-` : ''}api.pobble.com/`
  : DEV_API_URL

export default getClient({ baseUrl: API_ROOT })
