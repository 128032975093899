import React from 'react'
import injectSheet from 'react-jss'
import styles from './toast-component.styles'
import Alert from 'pobble-components/dist/alert'

const ToastComponent = ({ classes, type, children, closeToast }) => (
  <Alert hideTitle className={classes.alert} type={type} onClose={closeToast}>
    {children}
  </Alert>
)

export default injectSheet(styles)(ToastComponent)
