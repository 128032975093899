import THEME from 'pobble-components/dist/theme'
import { hover } from 'pobble-components/dist/jss-mixins'

const { FONT_SIZES, COLORS } = THEME

export default {
  selectHolder: {
    position: 'relative'
  },
  big: {
    '& select': {
      height: '50px',
      padding: '12px 20px',
      fontSize: '20px',
      lineHeight: '20px'
    },
    '& $arrowIcon': {
      width: '15px',
      top: '22px',
      right: '12px'
    }
  },
  camo: {
    '& $select': {
      borderColor: 'transparent'
    }
  },
  contrast: {
    '& $select': {
      borderColor: 'transparent',
      background: 'rgba(255, 255, 255, .15)',
      color: '#fff',
      '&:hover': {
        borderColor: 'transparent',
        '& + $arrowIcon': {
          color: '#fff'
        }
      }
    },
    '& $arrowIcon': {
      color: '#fff',
      '&:hover': {
        color: '#fff'
      }
    }
  },
  invalid: {
    '& $select': {
      borderColor: COLORS.RED.NORMAL,
      backgroundColor: '#FEF7F6',
      ...hover({
        borderColor: COLORS.RED.NORMAL,
        '& + $arrowIcon': {
          color: COLORS.RED.NORMAL
        }
      }),
      '& + $arrowIcon': {
        color: COLORS.RED.NORMAL
      }
    }
  },
  disabled: {
    '& $select': {
      cursor: 'default',
      backgroundColor: '#F9F9F9',
      borderColor: '#ECECEC !important'
    }
  },
  arrowIcon: {
    color: '#787878',
    transition: 'color 0.3s',
    position: 'absolute',
    right: '11px',
    top: '18px',
    pointerEvents: 'none'
  },
  lockIcon: {
    color: '#797979',
    position: 'absolute',
    right: '10px',
    top: '14px',
    pointerEvents: 'none'
  },
  select: {
    whiteSpace: 'nowrap',
    transition: 'border-color 0.3s',
    cursor: 'pointer',
    appearance: 'none',
    background: 'none',
    outline: 'none',
    border: '1px solid #ddd',
    borderRadius: '4px',
    height: '40px',
    display: 'block',
    width: '100%',
    padding: '0 34px 0 15px',
    ...FONT_SIZES.BODY_DEFAULT.STYLE,
    color: COLORS.TEXT.MID_GREY.NORMAL,
    ...hover({
      borderColor: COLORS.BLUE.NORMAL,
      '& + $arrowIcon': {
        color: COLORS.BLUE.NORMAL
      }
    })
  }
}
