import React from 'react'
import { useMe } from 'pobble-components/dist/pobble-user'
import { SubscriptionRequired } from 'pobble-components/dist/subscriptions'
import injectSheet from 'react-jss'
import styles from './inspector.styles'

const SubscriptionInspector = ({
  classes,
  children,
  policy,
  renderWhenRestricted
}) => {
  const { me } = useMe()
  const currentPolicy = policy(me)
  if (!currentPolicy.restricted) return children
  if (renderWhenRestricted)
    return renderWhenRestricted({ plan: currentPolicy.plan })
  return (
    <div className={classes.subscriptionRequiredHolder}>
      <SubscriptionRequired policy={currentPolicy} />
    </div>
  )
}

export default injectSheet(styles)(SubscriptionInspector)
