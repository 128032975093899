const animationTime = '100ms'

const styles = {
  overlay: {
    display: 'flex',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    padding: '40px 0',
    zIndex: '1000',
    '&.ReactModal__Overlay--after-open': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      transition: `background-color ${animationTime} ease-out`
    },
    '&.ReactModal__Overlay--before-close': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      transition: `background-color ${animationTime} ease-out`
    },
    overflow: 'auto'
  },
  modal: {
    marginLeft: '10px',
    marginRight: '10px',
    position: 'relative',
    borderRadius: '8px',
    boxShadow: '2px 5px 5px 0 rgba(0, 0, 0, .2)',
    background: '#fff',
    transform: 'scale(0.95)',
    opacity: '0',
    margin: 'auto',
    '&.ReactModal__Content': {
      outline: 'none'
    },
    '&.ReactModal__Content--after-open': {
      transform: 'scale(1)',
      opacity: '1',
      transition: `transform ${animationTime} ease-out, opacity ${animationTime} ease-out`
    },
    '&.ReactModal__Content--before-close': {
      opacity: '0',
      transform: 'scale(0.95)',
      transition: `transform ${animationTime} ease-out, opacity ${animationTime} ease-out`
    }
  },
  preventInteraction: {
    pointerEvents: 'none'
  },
  closeIcon: {
    color: `var(--gray)`,
    background: `var(--light-gray)`,
    width: '24px',
    height: '24px',
    borderRadius: '24px',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '20px',
    top: '20px',
    '&:hover': {
      border: '1px solid var(--gray--)'
    },
    '&:focus, &:active': {
      background: 'var(--gray)',
      color: 'var(--light-gray)'
    }
  },
  '@global': {
    '.ReactModal__Body--open': {
      overflow: 'hidden'
    }
  }
}

export { styles, animationTime }
