export default ({ COLORS }) => ({
  content: {
    width: '300px',
    minWidth: 'auto',
    padding: '0'
  },
  cancelButton: {},
  danger: {
    '& $cancelButton': {
      color: COLORS.TEXT.LIGHT_GREY.NORMAL
    }
  },
  actionButton: {
    minWidth: '100px',
    marginLeft: '5px'
  },
  text: {
    margin: '0',
    fontSize: '18px',
    lineHeight: '22px',
    padding: '20px'
  },
  buttonHolder: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '20px',
    background: '#f9f9f9',
    borderRadius: '0 0 8px 8px'
  },
  overlay: {
    zIndex: '9999'
  }
})
