import { toast } from 'pobble-components/dist/toast'
import { getErrorInfo } from 'pobble-components/dist/utils'
import { redirectToAuth, unsetToken } from 'pobble-components/dist/auth-helpers'
import { JWT_EXPIRED } from 'pobble-components/dist/cookies'

const handler = e => {
  const ref = getErrorInfo(e).ref

  const errorsMap = {
    e0044: `Please use PNG or JPEG format for the images`,
    e0045: `File is too large, please select a file less than 10mb`,
    e0048: `File is too large, please select a file less than 22mb`,
    e0049: `File is too large, please select a file less than 56mb`,
    e0051: `File is too large, please select a smaller file`,
    def: `
      Oops, something went wrong.
      If this continues to happen
      please contact hello@pobble.com.
      Ref: ${ref || 'unknown'}
    `
  }

  if (e.response && e.response.status === 401) {
    JWT_EXPIRED.set(true)
    unsetToken()
    redirectToAuth({ redirectBack: true })
  } else {
    toast.error(errorsMap[ref] || errorsMap.def, {
      autoClose: 6000
    })
  }
}

export default handler
