export default {
  pending: {
    '& td': {
      position: 'relative',
      pointerEvents: 'none',
      '&:after': {
        left: '0',
        top: '0',
        zIndex: '100',
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.5)',
        pointerEvents: 'none',
        animation: '0.3s fadeIn'
      }
    }
  },
  '@keyframes fadeIn': {
    from: { opacity: '0' },
    to: { opacity: '1' }
  }
}
