import React from 'react'
import injectSheet from 'react-jss'
import styles from './form-select.styles.js'
import PropTypes from 'prop-types'
import ArrowIcon from './arrow.jsx'
import LockIcon from 'react-icons/lib/fa/lock'
import classNames from 'classnames'

const FormSelect = ({
  classes,
  value,
  name,
  children,
  id,
  big,
  onChange,
  className,
  disabled,
  camo,
  contrast,
  validationError,
  selectClassName,
  selectProps,
  ...props
}) => (
  <div
    className={classNames(
      classes.selectHolder,
      {
        [classes.disabled]: disabled,
        [classes.big]: big,
        [classes.camo]: camo,
        [classes.invalid]: validationError,
        [classes.contrast]: contrast
      },
      className
    )}>
    <select
      disabled={disabled}
      value={value}
      name={name}
      onChange={onChange}
      className={classNames(classes.select, selectClassName)}
      id={id}
      {...selectProps}
      {...props}>
      {children}
    </select>
    {!disabled && <ArrowIcon className={classes.arrowIcon} />}
    {disabled && <LockIcon className={classes.lockIcon} />}
  </div>
)

FormSelect.propTypes = {
  /** Id of the select DOM node */
  id: PropTypes.string,
  /** Boolean value of checked state. Makes radio button controlled */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Name of the select */
  name: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /** On select change handler */
  onChange: PropTypes.func,
  /** Additional className if you want to restyle component */
  className: PropTypes.string,
  /** Disable select */
  disabled: PropTypes.bool,
  /** Set of options for select element */
  selectProps: PropTypes.object
}

export default injectSheet(styles)(FormSelect)
