import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './form-checkbox.styles.js'
import CheckIcon from 'react-icons/lib/fa/check'
import classNames from 'classnames'
import PropTypes from 'prop-types'

class FormCheckbox extends Component {
  render() {
    const {
      classes,
      id,
      name,
      checked,
      onChange,
      checkboxProps,
      disabled,
      className,
      ...props
    } = this.props
    return (
      <span
        className={classNames(
          classes.checkboxContainer,
          {
            [classes.checkboxDisabled]: disabled
          },
          className
        )}>
        <input
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          type="checkbox"
          className={classes.checkbox}
          {...checkboxProps}
          {...props}
        />
        <span className={classes.checkboxBG}>
          <span className={classes.mark}>
            <CheckIcon style={{ verticalAlign: 'top' }} />
          </span>
        </span>
      </span>
    )
  }
}

FormCheckbox.propTypes = {
  /** Id of the checkbox DOM node */
  id: PropTypes.string,
  /** Name of the checkbox DOM node */
  name: PropTypes.string,
  /** Boolean value of checked state. Make checkbox controlled */
  checked: PropTypes.bool,
  /** On checkbox status change handler */
  onChange: PropTypes.func,
  /** Makes checkbox disabled */
  disabled: PropTypes.bool,
  /** Additional className if you want to restyle component */
  className: PropTypes.string,
  /** Object with all additional checkbox props you want to pass. Check official checkbox documentation to get the idea what props os available */
  checkboxProps: PropTypes.object
}

export default injectSheet(styles)(FormCheckbox)
