import THEME from 'pobble-components/dist/theme'
import { hover } from 'pobble-components/dist/jss-mixins'

const { COLORS } = THEME

const styles = {
  holder: {
    position: 'relative',
    width: '100%'
  },
  formTextArea: {
    '-webkit-appearance': 'none',
    minHeight: '40px',
    height: '80px',
    padding: '10px',
    border: '1px solid #ececec',
    borderRadius: '4px',
    transition: 'border-color 0.3s ease-in-out',
    width: '100%',
    color: '#999',
    resize: 'vertical',
    fontSize: '16px',
    '&:focus': {
      color: '#555',
      borderColor: COLORS.BLUE.NORMAL
    },
    '&hover': {
      color: '#777',
      borderColor: COLORS.BLUE.NORMAL
    },
    '&::placeholder': {
      color: '#BBBBBB'
    }
  },
  invalid: {
    '& $formTextArea': {
      backgroundColor: '#FEF7F6',
      borderColor: COLORS.RED.NORMAL,
      '&:focus': {
        borderColor: COLORS.RED.NORMAL
      },
      ...hover({
        borerColor: COLORS.RED.NORMAL
      })
    }
  },
  disabled: {
    '& $formTextArea': {
      cursor: 'default',
      backgroundColor: '#F9F9F9',
      borderColor: '#ECECEC !important',
      paddingRight: '34px'
    }
  },
  disabledIcon: {
    color: '#797979',
    position: 'absolute',
    right: '10px',
    top: '14px',
    pointerEvents: 'none'
  },
  invalidWithMessage: {
    '& $formTextArea': {
      paddingRight: '32px'
    }
  },
  validationError: {
    position: 'absolute',
    right: '10px',
    top: '13px'
  }
}

export default styles
