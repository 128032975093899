import React from 'react'
import injectSheet from 'react-jss'
import styles from './modal-header.styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const ModalHeader = ({ classes, children, className }) => (
  <div className={classNames(classes.container, className)}>{children}</div>
)

ModalHeader.propTypes = {
  /** Additional className for restyling purposes */
  className: PropTypes.string
}

export default injectSheet(styles)(ModalHeader)
