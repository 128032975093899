const getNatureIconPath = icon =>
  `https://app.pobble.com/account/files/pupil-avatars/nature/${icon}.svg`
const getObjectIconPath = icon =>
  `https://app.pobble.com/account/files/pupil-avatars/objects/${icon}.svg`

const NATURE_ICONS_MAP = {
  AA4HCAEDDgQ: getNatureIconPath('bear'),
  DgAFCAYGDAo: getNatureIconPath('boar'),
  BwcBBgwABgE: getNatureIconPath('bolt'),
  CQEMAwcLAAQ: getNatureIconPath('bouquetofflowers'),
  AAIDCQYFBQk: getNatureIconPath('bunny'),
  CgUOAgwNBgg: getNatureIconPath('cat'),
  BAcFCwUODw8: getNatureIconPath('chicken'),
  Dw8IBAEJBAY: getNatureIconPath('cowface'),
  AA4KCQAAAQk: getNatureIconPath('crescentmoon'),
  BQMKCgcOAAM: getNatureIconPath('dog'),
  CAIDCgIJBQQ: getNatureIconPath('doveofpeace'),
  DQIHDAADCA4: getNatureIconPath('evergreen'),
  BQYKAQEKDAM: getNatureIconPath('fish'),
  Bg8KBQoHBwg: getNatureIconPath('frog'),
  CQ4DAQ8IAgU: getNatureIconPath('frontfacingchick'),
  BAcADw4ODwo: getNatureIconPath('hampster'),
  BwgADwEEAQI: getNatureIconPath('honeybee'),
  DQQHAwILAw8: getNatureIconPath('horseface'),
  AwQBDQUFDAE: getNatureIconPath('koala'),
  CgQIBgoNAQs: getNatureIconPath('ladybeetle'),
  DwkDBA0KBQ0: getNatureIconPath('monkeyface'),
  CQAPDgQLBAM: getNatureIconPath('mouse'),
  DAkHCwsAAgc: getNatureIconPath('mushroom'),
  DwMPDgEJBwY: getNatureIconPath('octopus'),
  DwwPDgkCBwk: getNatureIconPath('palmtree'),
  BggFBwAODA8: getNatureIconPath('pandaface'),
  DAwKAQwLAwk: getNatureIconPath('penguin'),
  DAMOAwsGDQw: getNatureIconPath('pig'),
  Dg4CAQcBCQA: getNatureIconPath('rooster'),
  Cg0EBAkLDg4: getNatureIconPath('snake'),
  AwYIBgUHDwI: getNatureIconPath('spider'),
  DQIDCAUHDAg: getNatureIconPath('sunflower'),
  AgMIAgUDCgc: getNatureIconPath('sunwithface'),
  CwYGBAALDgA: getNatureIconPath('tiger'),
  BwkBDAsOCg8: getNatureIconPath('wolfface')
}

const OBJECT_ICONS_MAP = {
  DgMJDw8EBwM: getObjectIconPath('artistpalette'),
  AA4NDAMLAgE: getObjectIconPath('billiards'),
  DgwCCw0CCQs: getObjectIconPath('bluebook'),
  CQ0MDAYMCwE: getObjectIconPath('darksunglasses'),
  AAYDAAUOCA4: getObjectIconPath('guitar'),
  Dg8DCQ4IAQU: getObjectIconPath('lollipop'),
  CgoLDAgGCQc: getObjectIconPath('lowerleftpencil'),
  CAkMDQIJBAw: getObjectIconPath('microphone'),
  AA8JAwUGAgo: getObjectIconPath('moviecamera'),
  DAELAgcFDg4: getObjectIconPath('musicascend'),
  DgcKAgkKBwY: getObjectIconPath('rugbyfootball'),
  Bw4MAAEEAQA: getObjectIconPath('soccerball'),
  DQEBBQsKAAc: getObjectIconPath('videogame'),
  CgAEDA4BCQk: getObjectIconPath('violin'),
  Bg4HBAUACQM: getObjectIconPath('watermelon')
}

const ICONS_MAP = { ...NATURE_ICONS_MAP, ...OBJECT_ICONS_MAP }

export { ICONS_MAP, NATURE_ICONS_MAP, OBJECT_ICONS_MAP }
