import React from 'react'
import injectSheet from 'react-jss'
import styles from './spinner.styles.js'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Spinner = ({ classes, className, ...props }) => (
  <div className={classNames(classes.spinner, className)} {...props}>
    <div />
  </div>
)

Spinner.propTypes = {
  /** Additional className to restyle component */
  className: PropTypes.string,
  /** Rewrite style of the component */
  style: PropTypes.object
}

export default injectSheet(styles)(Spinner)
