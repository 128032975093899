import isTouchDevice from 'is-touch-device'
import THEME from 'pobble-components/dist/theme'

const { BREAKPOINTS } = THEME

export const hover = style => ({ '&:hover': style })

export const nonTouch = style => (isTouchDevice() ? null : style)
export const touch = style => (!isTouchDevice() ? null : style)

export const fullVHExceptHeader = {
  height: 'calc(100vh - 70px)',
  [`@media (max-width: ${BREAKPOINTS.SM})`]: {
    height: 'calc(100vh - 50px)'
  }
}

export const fullVHExceptHeaderMin = {
  minHeight: 'calc(100vh - 70px)',
  [`@media (max-width: ${BREAKPOINTS.SM})`]: {
    minHeight: 'calc(100vh - 50px)'
  }
}

export const mediaTabletPortrait = style => ({
  [`@media (min-width: ${BREAKPOINTS.SM}) and (max-width: ${BREAKPOINTS.LG}) and (orientation: portrait)`]: style
})

export const mediaPhonePortrait = style => ({
  [`@media (max-width: 767px) and (orientation: portrait)`]: style
})

export const mediaPhoneLandscape = style => ({
  [`@media (max-width: 1024px) and (orientation: landscape)`]: style
})
