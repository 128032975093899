import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './user-avatar.styles.js'
import classNames from 'classnames'
import PropTypes from 'prop-types'

class UserAvatar extends Component {
  getColorClassName(letter) {
    const { classes } = this.props
    const charCode = letter.charCodeAt(0)
    if (charCode < 65 || charCode > 90) return classes.red
    if (charCode >= 65 && charCode <= 68) return classes.purple
    if (charCode >= 69 && charCode <= 72) return classes.green
    if (charCode >= 73 && charCode <= 76) return classes.blue
    if (charCode >= 77 && charCode <= 80) return classes.red
    if (charCode >= 81 && charCode <= 84) return classes.yellow
    if (charCode >= 85 && charCode <= 90) return classes.darkBlue
  }
  shouldComponentUpdate(nextProps) {
    return this.props.name !== nextProps.name
  }
  render() {
    const { name, classes, className } = this.props
    const letter = name.charAt(0).toUpperCase()
    const colorClassName = this.getColorClassName(letter)
    return (
      <span className={classNames(classes.avatar, colorClassName, className)}>
        {letter}
      </span>
    )
  }
}

UserAvatar.propTypes = {
  /** The name of the Pupil */
  name: PropTypes.string
}

export default injectSheet(styles)(UserAvatar)
