import { fullVHExceptHeader } from 'pobble-components/dist/jss-mixins'

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...fullVHExceptHeader
  },
  spinner: {
    opacity: '0',
    animation: '0.6s fadeIn ease-in forwards'
  },
  '@keyframes fadeIn': {
    from: { opacity: '0' },
    to: { opacity: '1' }
  }
}

export default styles
