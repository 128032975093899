import { hover } from 'pobble-components/dist/jss-mixins'
import THEME from 'pobble-components/dist/theme'

const { COLORS } = THEME

const link = {
  display: 'flex',
  fontSize: '1em',
  justifyContent: 'center',
  alignItems: 'center',
  width: '1.4em',
  height: '1.4em',
  borderRadius: '100%',
  cursor: 'pointer',
  margin: '0 4px',
  ...hover({
    color: COLORS.BLUE.NORMAL
  })
}

export default {
  container: {
    display: 'flex',
    listStyle: 'none',
    fontSize: '16px',
    color: '#999',
    margin: '0',
    padding: '0'
  },
  pageLink: link,
  breakLinkClassName: link,
  activeLinkClassName: {
    color: '#fff',
    background: COLORS.BLUE.NORMAL,
    cursor: 'default',
    ...hover({
      color: '#fff'
    })
  },
  previousLinkClassName: link,
  nextLinkClassName: link
}
