import THEME from 'pobble-components/dist/theme'

const { COLORS } = THEME

const styles = {
  container: {
    whiteSpace: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    padding: '10px 20px',
    color: '#666',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    textDecoration: 'none',
    '& em': {
      color: '#666',
      fontStyle: 'normal',
      transition: '300ms ease-in-out'
    },
    '&:hover em': {
      color: COLORS.BLUE.NORMAL
    }
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'normal'
  },
  avatar: {
    fontSize: '24px',
    marginRight: '14px',
    flexShrink: 0
  },
  timestamp: {
    color: '#a8a8a8',
    textAlign: 'right',
    width: '110px'
  }
}

export default styles
