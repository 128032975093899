import React, { Fragment } from 'react'
import styles from './no-rights-screen.styles'
import injectSheet from 'react-jss'
import FullpageError from 'pobble-components/dist/full-page-error'
import Button from 'pobble-components/dist/button'

const SignInButton = (
  <Button tall href={`/auth/?redirect=${window.location.href}`}>
    Sign into Pobble
  </Button>
)

const NoRightsScreen = () => (
  <FullpageError
    monster="guard"
    title="You are not allowed to view this page"
    status="403"
    desc={
      <Fragment>
        If you think this is an error,
        <br />
        please contact hello@pobble.com.
      </Fragment>
    }
    button={SignInButton}
  />
)

export default injectSheet(styles)(NoRightsScreen)
