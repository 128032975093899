import React, { Component } from 'react'
import { defaultState, reducer } from './store/reducer'
import ConfirmationDialogComponent from './confirmation-dialog'
import dispatcher from './store/dispatcher'
import { closeConfirmationDialog } from './store/actions'

class ConfirmationDialog extends Component {
  constructor(props) {
    super(props)
    this.state = defaultState
    dispatcher.register(action => this.setState(reducer(this.state, action)))
  }
  onClose() {
    dispatcher.dispatch(closeConfirmationDialog())
  }
  render() {
    const {
      opened,
      bodyText,
      confirmText,
      cancelText,
      action,
      theme
    } = this.state
    return (
      <ConfirmationDialogComponent
        content={bodyText}
        isOpen={opened}
        actionButtonText={confirmText}
        cancelButtonText={cancelText}
        theme={theme}
        action={action}
        onClose={this.onClose}
      />
    )
  }
}

export default ConfirmationDialog
