import React from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'

const DropMenu = injectSheet({
  container: {
    position: 'relative',
    '@media (max-width: 768px)': {
      padding: '24px 0',
      borderBottom: '1px solid #ececec',
      '&:first-child': {
        paddingTop: '0'
      }
    },
    '&:hover': {
      '& $menu': {
        '@media (min-width: 769px)': {
          visibility: 'visible',
          transform: 'translate3d(0, 0, 0)',
          opacity: '1'
        }
      }
    }
  },
  menu: {
    '@media (min-width: 769px)': {
      visibility: 'hidden',
      transition: '.2s cubic-bezier(0,0,0,1)',
      transform: 'translate3d(0, 5px, 0)',
      opacity: '0',
      position: 'absolute',
      right: '0',
      paddingTop: '10px',
      '& > div': {
        minWidth: '180px',
        color: '#a9afbd',
        fontSize: '12px',
        boxShadow: '0 5px 15px 0 rgba(0,0,0,0.15)',
        marginTop: '0',
        background: '#fff',
        borderRadius: '4px',
        whiteSpace: 'nowrap',
        marginRight: '4px'
      },
      '&:after': {
        border: 'solid transparent',
        borderColor: 'rgba(136,183,213,0)',
        borderBottomColor: '#fff',
        borderWidth: '7px',
        bottom: '100%',
        content: '""',
        height: '0',
        marginLeft: '-7px',
        position: 'absolute',
        pointerEvents: 'none',
        right: '10px',
        top: '-4px',
        width: '0'
      }
    }
  },
  headerBox: {}
})(({ classes, children }) => {
  return children(
    ({ children, className }) => (
      <div className={classNames(classes.container, className)}>{children}</div>
    ),
    ({ children, className }) => (
      <div className={classNames(classes.menu, className)}>
        <div>{children}</div>
      </div>
    )
  )
})

export default DropMenu
