import React, { useMemo, useState } from 'react'
import injectSheet from 'react-jss'
import patternImg from 'pobble-components/dist/dev-page/pattern.png'
import { apiClient } from 'pobble-components/dist/api'
import { setToken } from 'pobble-components/dist/auth-helpers'
import ButtonElement from 'pobble-components/dist/button-element'

const emoji = [
  '😂',
  '❤️',
  '✨',
  '🙏',
  '😍',
  '😊',
  '👍',
  '💕',
  '👀',
  '📸',
  '💥',
  '💫',
  '🦍',
  '🐶',
  '🦊',
  '🦁',
  '🐻',
  '🐨',
  '🌈',
  '🔥',
  '🤘',
  '🤌',
  '👌',
  '✌️'
]

const devPageStyles = {
  container: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    zIndex: '2000'
  },
  greeting: {
    padding: '20px',
    textAlign: 'center',
    backgroundImage: `linear-gradient(180deg, rgba(0, 175, 236, .95), rgba(0, 175, 236, .95)), url("${patternImg}")`,
    backgroundSize: 'auto, 250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#fff',
    height: '100vh',
    '& > h2': {
      fontSize: '70px',
      lineHeight: '70px',
      margin: '0 0 10px'
    },
    '& > p': {
      fontSize: '30px',
      lineHeight: '40px',
      letterSpacing: '1px',
      margin: '0'
    }
  },
  login: {
    margin: '16px 0',
    font: 'var(--body)',
    opacity: '.7'
  },
  form: {
    display: 'none',
    width: '240px',
    margin: '24px auto',
    display: 'grid',
    gap: '16px',
    font: 'var(--body)',
    textAlign: 'center',
    '& input': {
      padding: '12px 16px',
      borderRadius: '4px',
      background: '#fff',
      border: 'none',
      width: '100%',
      textAlign: 'center'
    },
    '& button': {
      padding: '12px 16px',
      borderRadius: '4px',
      border: 'none',
      background: 'var(--yellow)',
      cursor: 'pointer',
      fontWeight: 'bold'
    }
  }
}

const DevPage = injectSheet(devPageStyles)(({ classes }) => {
  const [showLogin, setShowLogin] = useState(false)
  const threeEmoji = useMemo(
    () =>
      [...Array(emoji.length).keys()]
        .sort(() => 0.5 - Math.random())
        .slice(0, 3)
        .map(index => emoji[index]),
    []
  )
  return (
    <div className={classes.container}>
      <div className={classes.greeting}>
        <h2>{threeEmoji.map(emoji => emoji + ' ')}</h2>
        <p>Welcome to Pobble</p>
        {!showLogin && (
          <ButtonElement
            onClick={() => setShowLogin(true)}
            className={classes.login}>
            Sign In
          </ButtonElement>
        )}
        {showLogin && (
          <form
            onSubmit={e => {
              e.preventDefault()
              apiClient
                .post('v3/email_authentication', {
                  email: e.target.email.value,
                  password: e.target.password.value,
                  remember_me: true
                })
                .then(res => {
                  setToken(res.data.jwt)
                  window.location.reload()
                })
            }}
            className={classes.form}>
            <input type="email" name="email" placeholder="Email" />
            <input type="password" name="password" placeholder="Password" />
            <button type="submit">Sign in</button>
          </form>
        )}
      </div>
    </div>
  )
})

export default DevPage
