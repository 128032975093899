import React from 'react'
import injectSheet from 'react-jss'
import styles from './cell.styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Cell = ({ classes, children, className, pointer, center, right }) => (
  <td
    className={classNames(
      classes.container,
      { [classes.pointer]: pointer },
      { [classes.center]: center },
      { [classes.right]: right },
      className
    )}>
    {children}
  </td>
)

Cell.propTypes = {
  /** Cursor pointer for a cell */
  pointer: PropTypes.bool,
  /** Align cell to the center */
  center: PropTypes.bool,
  /** Align cell to the right */
  right: PropTypes.bool,
  /** Additional className for restyling purposes */
  className: PropTypes.string
}

export default injectSheet(styles)(Cell)
