import React from 'react'
import injectSheet from 'react-jss'
import { styles, animationTime } from './modal.styles'
import classNames from 'classnames'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import ButtonElement from 'pobble-components/dist/button-element'

ReactModal.setAppElement('body')

const Modal = ({
  classes,
  isOpen,
  children,
  closeButton,
  requestClose,
  preventInteraction,
  className,
  overlayClassName,
  // eslint-disable-next-line no-unused-vars
  green,
  ...props
}) => (
  <ReactModal
    shouldReturnFocusAfterClose={false}
    isOpen={!!isOpen}
    className={classNames(
      classes.modal,
      {
        [classes.preventInteraction]: preventInteraction
      },
      className
    )}
    overlayClassName={classNames(classes.overlay, overlayClassName)}
    closeTimeoutMS={parseInt(animationTime, 10)}
    {...props}>
    {closeButton && (
      <ButtonElement className={classes.closeIcon} onClick={requestClose}>
        <FontAwesomeIcon icon={faTimes} />
      </ButtonElement>
    )}
    {children}
  </ReactModal>
)

Modal.propTypes = {
  /** Controls visibility of close button in the corner */
  closeButton: PropTypes.bool,
  /** This function will fire when someone tries to close modal with closeButton */
  requestClose: PropTypes.func,
  /** If true sets pointerEvents: 'none' for entire modal area */
  preventInteraction: PropTypes.bool,
  /** Additional className for restyling purposes */
  className: PropTypes.string,
  /** Additional className for the overlay */
  overlayClassName: PropTypes.string
}

export default injectSheet(styles)(Modal)
