import Cookies from 'js-cookie'
import { COOKIE_SETTINGS } from 'pobble-components/dist/setup'

/**
 * This component allows communication between different SPAs
 * We set a cookie in one SPA, then receive it in another
 */
export class PobbleCookie {
  constructor(cookieName) {
    this.name = cookieName
  }
  set(value, settings) {
    return Cookies.set(this.name, value, {
      ...COOKIE_SETTINGS,
      ...settings
    })
  }
  get() {
    return Cookies.get(this.name, COOKIE_SETTINGS)
  }
  remove() {
    return Cookies.remove(this.name, COOKIE_SETTINGS)
  }
  pull() {
    const cookie = this.get()
    this.remove()
    return cookie
  }
}

/**
 * Set to true to show last slide on the builder
 *   next time user gets there
 */
export const LP_SHOW_LAST_SLIDE = new PobbleCookie('LP_SHOW_LAST_SLIDE')

/**
 * Specify the active builder slide
 *  for the next time user gets there
 */
export const BUILDER_ACTIVE_SLIDE_ID = new PobbleCookie(
  'BUILDER_ACTIVE_SLIDE_ID'
)

/**
 * Notify that jwt token has expired
 */
export const JWT_EXPIRED = new PobbleCookie('JWT_EXPIRED')

/**
 * Specify a redirect after authentication
 */
export const AUTH_REDIRECT = new PobbleCookie('AUTH_REDIRECT')

/**
 * Identify user's first ever session on the platfform
 */
export const FIRST_SESSION = new PobbleCookie('FIRST_SESSION')
