import React from 'react'
import injectSheet from 'react-jss'
import styles from './form-text-area.styles.js'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ValidationError from '../form-input/validation-error/validation-error.jsx'
import LockIcon from 'react-icons/lib/fa/lock'
import { isIOS } from 'pobble-components/dist/utils'

const FormTextArea = ({
  classes,
  textAreaProps,
  className,
  validationError,
  autoFocus,
  disabled,
  ...props
}) => {
  return (
    <div
      className={classNames(classes.holder, {
        [classes.invalid]: validationError,
        [classes.disabled]: disabled,
        [classes.invalidWithMessage]:
          validationError && validationError !== true
      })}>
      <textarea
        className={classNames(classes.formTextArea, className)}
        disabled={disabled}
        autoFocus={isIOS ? false : autoFocus}
        {...textAreaProps}
        {...props}
      />
      {disabled && <LockIcon className={classes.disabledIcon} />}
      {validationError && (
        <ValidationError
          className={classes.validationError}
          error={validationError}
        />
      )}
    </div>
  )
}

FormTextArea.propTypes = {
  /** Additional className if you want to restyle component */
  className: PropTypes.string,
  /** Show validation error */
  validationError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Object with all additional textAreaProps props you want to pass. Check official textAreaProps documentation to get the idea what props os available */
  textAreaProps: PropTypes.object
}

export default injectSheet(styles)(FormTextArea)
