import React, { Component } from 'react'
import injectSheet from 'react-jss'
import styles from './button.styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Spinner from 'pobble-components/dist/spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons/faCaretLeft'

class Button extends Component {
  render() {
    const {
      as,
      green,
      darkBlue,
      white,
      purple,
      classes,
      children,
      secondary,
      danger,
      onClick,
      dangerSecondary,
      success,
      successSecondary,
      warning,
      warningSecondary,
      tall,
      big,
      disabled,
      action,
      href,
      _blank,
      wide,
      className,
      nodeRef,
      simple,
      tertiary,
      type,
      span,
      style,
      icon,
      alignLeft,
      alignRight,
      alignCenter,
      bleed,
      pending,
      back,
      minw,
      to
    } = this.props

    const buttonIcon = !back ? icon : <FontAwesomeIcon icon={faCaretLeft} />

    const Component = as ? as : href ? 'a' : span ? 'span' : 'button'

    const additionalProps =
      Component === 'button'
        ? {
            type: type || 'button'
          }
        : null

    return (
      <Component
        to={to}
        style={style}
        disabled={disabled || pending}
        ref={nodeRef}
        target={_blank && '_blank'}
        href={!disabled && href ? href : undefined}
        className={classNames(
          classes.button,
          className,
          { [classes.buttonTall]: tall },
          { [classes.big]: big },
          { [classes.secondary]: secondary },
          { [classes.danger]: danger },
          { [classes.success]: success },
          { [classes.warning]: warning },
          { [classes.disabled]: disabled },
          { [classes.wide]: wide },
          { [classes.dangerSecondary]: dangerSecondary },
          { [classes.successSecondary]: successSecondary },
          { [classes.warningSecondary]: warningSecondary },
          { [classes.simple]: simple },
          { [classes.alignLeft]: alignLeft },
          { [classes.alignRight]: alignRight },
          { [classes.alignCenter]: alignCenter },
          { [classes.bleed]: bleed },
          { [classes.green]: green },
          { [classes.darkBlue]: darkBlue },
          { [classes.white]: white },
          { [classes.purple]: purple },
          { [classes.back]: back },
          { [classes.tertiary]: tertiary },
          { [classes.minw]: minw }
        )}
        onClick={action || onClick}
        {...additionalProps}>
        {!!buttonIcon && !pending && (
          <div className={classes.iconHolder}>{buttonIcon}</div>
        )}
        {pending && (
          <div className={classes.iconHolder}>
            <Spinner className={classes.spinner} />
          </div>
        )}
        {children}
      </Component>
    )
  }
}

Button.propTypes = {
  /** Makes button taller */
  tall: PropTypes.bool,
  /** Applies secondary theme to the button */
  secondary: PropTypes.bool,
  /** Applies danger theme to the button */
  danger: PropTypes.bool,
  /** Applies danger secondary theme to the button */
  dangerSecondary: PropTypes.bool,
  /** Applies success theme to the button */
  success: PropTypes.bool,
  /** Applies success secondary theme to the button */
  successSecondary: PropTypes.bool,
  /** Applies warning theme to the button */
  warning: PropTypes.bool,
  /** Applies danger secondary theme to the button */
  warningSecondary: PropTypes.bool,
  /** Disables button */
  disabled: PropTypes.bool,
  /** Click handler for the button */
  action: PropTypes.func,
  /** This prop will make your button a link pointing to this url */
  href: PropTypes.string,
  /** In case you specified href this prop will make your url to open in a new tab */
  _blank: PropTypes.bool,
  /** Makes full width button */
  wide: PropTypes.bool,
  /** Add your own classname to the button  */
  className: PropTypes.string,
  /** Restyle button */
  style: PropTypes.object,
  /** Node reference */
  nodeRef: PropTypes.func,
  /** Apply simple theme to button */
  simple: PropTypes.bool,
  /** By default Button will render button component. You can specify type of the button with this prop */
  type: PropTypes.string,
  /** Icon on the left */
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}

Button.defaultProps = {
  tall: false,
  secondary: false,
  danger: false,
  dangerSecondary: false,
  success: false,
  successSecondary: false,
  warning: false,
  warningSecondary: false,
  disabled: false,
  wide: false,
  simple: false
}

export default injectSheet(styles)(Button)
