import React from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import CloseIcon from 'react-icons/lib/fa/times-circle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation'
import { faInfo } from '@fortawesome/pro-solid-svg-icons/faInfo'
import PropTypes from 'prop-types'

const styles = {
  container: {
    padding: '16px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold'
  },
  icon: {
    width: '32px',
    height: '32px',
    margin: '0 16px 0 0',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: '0'
  },
  title: {
    margin: '0 4px 0 0',
    fontWeight: 'bold'
  },
  success: {
    color: '#48640c',
    backgroundColor: '#f8faf5',
    '& $icon': {
      background: 'rgb(72 100 12 / 15%)'
    }
  },
  info: {
    color: '#0071c2',
    backgroundColor: '#f1f7fe',
    '& $icon': {
      background: 'rgb(0 113 194 / 14%)'
    }
  },
  warning: {
    color: '#996600',
    backgroundColor: '#fef7ec',
    '& $icon': {
      background: 'rgb(153 102 0 / 15%)'
    }
  },
  error: {
    color: '#db3915',
    background: '#f9d9d3',
    '& $icon': {
      background: 'rgb(219 57 21 / 18%)'
    }
  },
  text: {
    margin: '0',
    flex: '1',
    font: 'var(--body-)',
    fontWeight: 'bold',
    '& a': {
      textDecoration: 'underline',
      color: 'currentColor'
    }
  },
  closeButton: {
    width: '16px',
    height: '16px',
    margin: '0 0 0 20px',
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, .4)',
    alignSelf: 'flex-start',
    '& > svg': {
      display: 'block',
      width: '100%',
      height: '100%'
    }
  }
}

const alertsMap = {
  success: {
    icon: <FontAwesomeIcon icon={faCheck} />,
    title: 'Success'
  },
  info: {
    icon: <FontAwesomeIcon icon={faInfo} />,
    title: 'Info'
  },
  warning: {
    icon: <FontAwesomeIcon icon={faExclamation} />,
    title: 'Warning'
  },
  error: {
    icon: <FontAwesomeIcon icon={faExclamation} />,
    title: 'Error'
  }
}

const Alert = ({
  classes,
  hideTitle,
  type,
  children,
  onClose,
  className,
  statusText,
  ...props
}) => {
  const alertData = alertsMap[type]
  return (
    <div
      className={classNames(classes.container, classes[type], className)}
      {...props}>
      <div className={classes.icon}>{alertData.icon}</div>
      <p className={classes.text}>
        {!hideTitle && (
          <strong className={classes.title}>
            {statusText || alertData.title + ':'}
          </strong>
        )}
        <span>{children}</span>
      </p>
      {onClose && (
        <span
          onClick={onClose}
          role="button"
          tabIndex="0"
          className={classes.closeButton}>
          <CloseIcon />
        </span>
      )}
    </div>
  )
}

Alert.propTypes = {
  /** Type of the alert */
  type: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired,
  /** Callback to be fired when user clicks on close button */
  onClose: PropTypes.func
}

export default injectSheet(styles)(Alert)
