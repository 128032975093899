import React from 'react'
import styles from './table.styles'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Table = ({ classes, children, className, pending }) => (
  <div className={classNames(classes.wrapper, { [classes.pending]: pending })}>
    <table
      className={classNames(classes.container, className)}
      cellSpacing="0"
      cellPadding="0">
      {children}
    </table>
  </div>
)

Table.propTypes = {
  /** Table content */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /** Additional className for restyling purposes */
  className: PropTypes.string
}

export default injectSheet(styles)(Table)
