import React, { Fragment, useState, useContext } from 'react'
import {
  SubscriptionDueInvoiceDialog,
  SubscriptionRequiredDialog,
  SubscriptionContext
} from 'pobble-components/dist/subscriptions'
import { withMe } from 'pobble-components/dist/pobble-user'
import Cookies from 'js-cookie'
import { COOKIE_SETTINGS } from 'pobble-components/dist/setup'
import { FIRST_SESSION } from 'pobble-components/dist/cookies'

const Dialogs = ({ me }) => {
  const { subsDialogState, setSubsDialogState } = useContext(
    SubscriptionContext
  )

  const dueInvoiceHasBeenClosedCookie = Cookies.get(
    'SUBSCRIPTIONS:DUE_INVOICE_DIALOG_CLOSED'
  )
  const dueInvoiceHasBeenClosedTimes = !dueInvoiceHasBeenClosedCookie
    ? 0
    : parseInt(dueInvoiceHasBeenClosedCookie)
  const dueInvoiceBlocked = dueInvoiceHasBeenClosedTimes >= 3

  /**
   * Don't show due invoice modal on the first user session
   *   to unblock normal onboarding and not overwhelm
   *   user with tons of modals
   */
  const [dueInvoiceOpen, setDueInvoiceOpen] = useState(
    !FIRST_SESSION.get() &&
      me.account.individual_subscription.due_invoice &&
      window.location.href.indexOf('/membership') === -1 &&
      (!Cookies.get('SUBSCRIPTIONS:DUE_INVOICE_DIALOG_SEEN') ||
        dueInvoiceBlocked)
  )

  dueInvoiceOpen &&
    Cookies.set('SUBSCRIPTIONS:DUE_INVOICE_DIALOG_SEEN', true, {
      ...COOKIE_SETTINGS,
      expires: 1
    })

  return (
    <Fragment>
      <SubscriptionDueInvoiceDialog
        blocked={dueInvoiceBlocked}
        requestClose={() => {
          Cookies.set(
            'SUBSCRIPTIONS:DUE_INVOICE_DIALOG_CLOSED',
            dueInvoiceHasBeenClosedTimes + 1,
            {
              ...COOKIE_SETTINGS,
              expires: 10
            }
          )
          setDueInvoiceOpen(false)
        }}
        isOpen={dueInvoiceOpen}
      />
      <SubscriptionRequiredDialog
        requestClose={() =>
          setSubsDialogState({ ...subsDialogState, open: false })
        }
        isOpen={subsDialogState.open}
      />
    </Fragment>
  )
}

export default withMe(Dialogs)
