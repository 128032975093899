import React, { Fragment } from 'react'

const LineBreak = ({ children }) =>
  !children ? (
    <Fragment />
  ) : (
    children.split('\n').map((line, index) => (
      <Fragment key={index}>
        {line}
        <br />
      </Fragment>
    ))
  )

export default LineBreak
