import React, { useState } from 'react'
import injectSheet from 'react-jss'
import ButtonElement from 'pobble-components/dist/button-element'
import teacher from 'pobble-components/dist/select-role/teacher.svg'
import parent from 'pobble-components/dist/select-role/parent.svg'
import student from 'pobble-components/dist/select-role/student.svg'
import schoolLeader from 'pobble-components/dist/select-role/school-leader.svg'
import englishLeader from 'pobble-components/dist/select-role/english-leader.svg'
import other from 'pobble-components/dist/select-role/other.svg'
import classNames from 'classnames'
import Check from './check.jsx'
import Button from 'pobble-components/dist/button'
import { apiClient } from 'pobble-components/dist/api'

const RoleButton = injectSheet({
  button: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#fff',
    borderRadius: '8px',
    border: '1px solid #D6D7DF',
    padding: '24px',
    height: '200px',
    width: '100%',
    boxShadow: '0 1px 2px rgba(0, 0, 0, .1)',
    '&:hover, &:focus': {
      boxShadow: '0 0 0 2px rgba(37, 64, 143, .2), 0 1px 2px rgba(0, 0, 0, .1)'
    },
    '@media (min-width: 768px)': {
      width: '208px'
    },
    '& *': {
      pointerEvents: 'none'
    }
  },
  check: {
    position: 'absolute',
    top: '8px',
    right: '8px'
  },
  title: {
    margin: '0',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'var(--gray)',
    lineHeight: '1.2'
  },
  description: {
    margin: '0',
    fontSize: '12px',
    color: 'var(--gray--)',
    lineHeight: '1.2',
    maxWidth: '160px',
    '@media (min-width: 768px)': {
      minHeight: '28px'
    }
  },
  text: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  active: {
    borderColor: '#25408F',
    boxShadow: '0 0 0 2px rgba(37, 64, 143, .2), 0 1px 2px rgba(0, 0, 0, .1)'
  }
})(({ classes, title, description, icon, active, onClick, className }) => {
  return (
    <ButtonElement
      onClick={onClick}
      className={classNames(
        classes.button,
        { [classes.active]: active },
        className
      )}>
      {active && <Check className={classes.check} />}
      {icon}
      <div className={classes.text}>
        <h3 className={classes.title}>{title}</h3>
        <p className={classes.description}>{description}</p>
      </div>
    </ButtonElement>
  )
})

export const SelectRole = injectSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
    alignItems: 'center',
    textAlign: 'center',
    padding: '24px'
  },
  options: {
    listStyle: 'none',
    padding: '0',
    margin: '0',
    display: 'grid',
    gridTemplateColumns: '1fr',
    width: '100%',
    gap: '16px',
    '@media (min-width: 768px)': {
      gridTemplateColumns: '1fr 1fr 1fr'
    }
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    gap: '16px',
    alignItems: 'center',
    '& h2': {
      margin: '0',
      color: 'var(--gray)'
    },
    '& p': {
      margin: '0',
      color: 'var(--gray-)',
      font: 'var(--body-)'
    }
  },
  button: {
    minWidth: '160px'
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    alignItems: 'center'
  }
})(({ classes, onSuccess, onError, description }) => {
  const [active, setActive] = useState(null)
  const [pending, setPending] = useState(false)

  function setRole() {
    setPending(true)
    apiClient
      .post(`/v3/me/role_in_life`, {
        role_in_life: active
      })
      .then(() => {
        const success = onSuccess && onSuccess()
        if (success.then) {
          success.then(() => setPending(false))
        } else {
          setPending(false)
        }
      })
      .catch(() => {
        setPending(false)
        onError()
      })
  }

  return (
    <div className={classes.container}>
      {!description ? (
        <div className={classes.description}>
          <h2 className={classes.title}>
            Which of these
            <br />
            best describes your role?
          </h2>
        </div>
      ) : (
        description
      )}
      <div className={classes.form}>
        <ul className={classes.options}>
          <li>
            <RoleButton
              className="metrics__role--teacher_in_school"
              active={active === 'teacher_in_school'}
              onClick={() => setActive('teacher_in_school')}
              title="Teacher"
              description="I teach students whom I want to inspire to write"
              icon={<img src={teacher} alt="Teacher" />}
            />
          </li>
          <li>
            <RoleButton
              className="metrics__role--english_leader_in_school"
              active={active === 'english_leader_in_school'}
              onClick={() => setActive('english_leader_in_school')}
              title="English leader"
              description="I lead on English or writing in an educational setting"
              icon={<img src={englishLeader} alt="Teacher" />}
            />
          </li>
          <li>
            <RoleButton
              className="metrics__role--school_administrator"
              active={active === 'school_administrator'}
              onClick={() => setActive('school_administrator')}
              title="School leader"
              description="I am responsible for teachers in an educational setting"
              icon={<img src={schoolLeader} alt="Teacher" />}
            />
          </li>
          <li>
            <RoleButton
              className="metrics__role--parent"
              active={active === 'parent'}
              onClick={() => setActive('parent')}
              title="Parent"
              description="I have children I want to inspire to write"
              icon={<img src={parent} alt="Teacher" />}
            />
          </li>
          <li>
            <RoleButton
              className="metrics__role--student"
              active={active === 'student'}
              onClick={() => setActive('student')}
              title="Student / Child"
              description="I would like to improve my writing"
              icon={<img src={student} alt="Teacher" />}
            />
          </li>
          <li>
            <RoleButton
              className="metrics__role--other"
              active={active === 'other'}
              onClick={() => setActive('other')}
              title="Other"
              description="I am doing something else"
              icon={<img src={other} alt="Teacher" />}
            />
          </li>
        </ul>
        <Button
          action={setRole}
          disabled={!active}
          pending={pending}
          darkBlue
          tall
          minw
          className={classes.button}>
          {pending ? 'Saving...' : 'Complete my account'}
        </Button>
      </div>
    </div>
  )
})
