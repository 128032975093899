import THEME from 'pobble-components/dist/theme'

const { BREAKPOINTS } = THEME

const styles = {
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  container: {
    position: 'relative',
    zIndex: '100',
    boxSizing: 'border-box',
    '& *': {
      boxSizing: 'border-box'
    },
    fontSize: '14px',
    lineHeight: '17px',
    height: '70px',
    color: '#fff',
    background: `var(--blue)`,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [`@media (max-width: ${BREAKPOINTS.SM})`]: {
      height: '50px',
      padding: '5px 10px'
    }
  },
  studentHeader: {
    background: `var(--green)`
  },
  logo: {
    width: '82px',
    height: '42px',
    [`@media (max-width: ${BREAKPOINTS.SM})`]: {
      width: '60px',
      height: '100%'
    }
  },
  logoIcon: {
    width: '100%',
    height: '100%'
  },
  nav: {
    animation: 'fadeIn 0.3s',
    display: 'flex',
    height: '100%',
    alignItems: 'center'
  }
}

export default styles
