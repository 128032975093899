import React from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import ValidationError from './validation-error/validation-error.jsx'
import { isIOS } from 'pobble-components/dist/utils'
import THEME from 'pobble-components/dist/theme'
import { hover } from 'pobble-components/dist/jss-mixins'
import ButtonElement from 'pobble-components/dist/button-element'

const { COLORS } = THEME

const styles = {
  holder: {
    position: 'relative',
    width: '100%'
  },
  big: {
    '& $formInput': {
      height: '50px',
      padding: '15px 20px',
      fontSize: '20px',
      lineHeight: '20px',
      '&:active': {
        fontSize: '20px'
      }
    },
    '& $validationError': {
      top: '18px',
      right: '14px'
    }
  },
  camo: {
    '& $formInput': {
      borderColor: 'transparent',
      color: COLORS.TEXT.DARK_GREY.NORMAL,
      ...hover({
        color: COLORS.TEXT.DARK_GREY.NORMAL
      })
    }
  },
  center: {
    '& $formInput': {
      paddingRight: '34px',
      paddingLeft: '34px',
      textAlign: 'center'
    }
  },
  disabled: {
    '& $formInput': {
      cursor: 'default',
      backgroundColor: '#F9F9F9',
      borderColor: '#ECECEC !important',
      paddingRight: '34px'
    }
  },
  clearable: {
    '& $formInput': {
      paddingRight: '34px'
    }
  },
  formInput: {
    fontSize: '16px',
    height: '40px',
    padding: '10px 15px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    '-webkit-appearance': 'none',
    transition:
      'border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out',
    width: '100%',
    color: '#999',
    '&:focus': {
      color: '#555',
      borderColor: COLORS.BLUE.NORMAL
    },
    '&:active': {
      fontSize: '16px'
    },
    ...hover({
      color: '#777',
      borderColor: COLORS.BLUE.NORMAL
    }),
    '&::placeholder': {
      color: '#BBBBBB'
    }
  },
  invalid: {
    '& $formInput': {
      backgroundColor: '#FEF7F6',
      borderColor: COLORS.RED.NORMAL,
      '&:focus': {
        borderColor: COLORS.RED.NORMAL
      },
      ...hover({
        borerColor: COLORS.RED.NORMAL
      })
    }
  },
  invalidWithMessage: {
    '& $formInput': {
      paddingRight: '34px'
    }
  },
  disabledIcon: {
    color: '#797979',
    position: 'absolute',
    right: '10px',
    top: '14px',
    pointerEvents: 'none',
    fontSize: '10px'
  },
  validationError: {
    animation: 'fadeIn .3s forwards',
    position: 'absolute',
    right: '10px',
    top: '13px'
  },
  clear: {
    position: 'absolute',
    right: '10px',
    top: '12px',
    fontSize: '16px',
    color: '#ccc'
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  }
}

const FormInput = ({
  classes,
  className,
  camo,
  center,
  validationError,
  big,
  inputRef,
  disabled,
  autoFocus,
  clearable,
  onClear,
  ...props
}) => {
  return (
    <div
      className={classNames(classes.holder, {
        [classes.center]: center,
        [classes.disabled]: disabled,
        [classes.clearable]: clearable,
        [classes.camo]: camo,
        [classes.invalid]: validationError,
        [classes.big]: big,
        [classes.invalidWithMessage]:
          validationError && validationError !== true
      })}>
      <input
        ref={inputRef}
        className={classNames(classes.formInput, className)}
        disabled={disabled}
        autoFocus={isIOS ? false : autoFocus}
        {...props}
      />
      {clearable && !disabled && !!props.value && (
        <ButtonElement
          onClick={() => {
            if (onClear) {
              onClear()
            } else {
              props.onChange({ target: { value: '' } })
            }
          }}
          className={classes.clear}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </ButtonElement>
      )}
      {disabled && (
        <FontAwesomeIcon icon={faLock} className={classes.disabledIcon} />
      )}
      {validationError && (
        <ValidationError
          className={classes.validationError}
          error={validationError}
        />
      )}
    </div>
  )
}

FormInput.propTypes = {
  /** Larger size */
  big: PropTypes.bool,
  /** Additional className if you want to restyle component */
  className: PropTypes.string,
  /** Align text to center */
  center: PropTypes.bool,
  /** Show validation error */
  validationError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default injectSheet(styles)(FormInput)
