const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  userAvatar: {
    marginRight: '10px',
    flexShrink: '0'
  }
}

export default styles
