import React, { Fragment } from 'react'
import styles from './age-group.styles'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'

const AgeGroup = ({ classes, order, children, className, data }) => {
  const ageOrder = data ? data.order : order
  const ageTitle = data ? data.title : children
  const notAssigned = !data && !order && !children
  if (order || children)
    console.warn(
      'AgeGroup: order and children props are depricated, please use data prop instead'
    )
  return (
    <span
      className={classNames(
        classes.ageGroup,
        classes[`order${ageOrder}`],
        { [classes.notAssigned]: notAssigned },
        className
      )}>
      {notAssigned && (
        <Fragment>
          <FontAwesomeIcon
            className={classes.naIcon}
            icon={faExclamationTriangle}
          />
          Missing
        </Fragment>
      )}
      {!notAssigned && ageTitle}
    </span>
  )
}

AgeGroup.propTypes = {
  /** Order of the age group from the server */
  order: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15
  ]),
  /** Text of the button (any react element will be rendered) */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /** Additional className if you want to restyle component */
  className: PropTypes.string
}

export default injectSheet(styles)(AgeGroup)
