import React, { useContext, useState, useRef } from 'react'
import injectSheet from 'react-jss'
import styles from './nav.styles'
import classNames from 'classnames'
import BurgerButton from './burger-button/burger-button.jsx'
import AuthorisedMenu from '../authorised-menu/authorised-menu.jsx'
import UnauthorisedMenu from '../unauthorised-menu/unauthorised-menu.jsx'
import bodyScrollLock from 'body-scroll-lock'
import HeaderContext from '../context'

const Nav = ({ classes }) => {
  const { className, authorised } = useContext(HeaderContext)
  const [menuOpened, setMenuOpened] = useState(false)
  const menuRef = useRef(null)
  const toggleMenu = () => {
    menuOpened
      ? bodyScrollLock.enableBodyScroll(menuRef.current)
      : bodyScrollLock.disableBodyScroll(menuRef.current)
    setMenuOpened(!menuOpened)
  }
  return (
    <nav className={classNames(classes.container, className)}>
      <span onClick={toggleMenu} className={classes.toggleMobileMenu}>
        <BurgerButton opened={menuOpened} /> Menu
      </span>
      <span
        className={classNames(classes.mobileOverlay, {
          [classes.mobileOverlayShown]: menuOpened
        })}
      />
      <div
        ref={menuRef}
        className={classNames(classes.menuHolder, {
          [classes.menuHolderOpened]: menuOpened
        })}>
        {authorised && <AuthorisedMenu />}
        {!authorised && <UnauthorisedMenu />}
      </div>
    </nav>
  )
}

export default injectSheet(styles)(Nav)
