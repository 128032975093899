import React from 'react'
import injectSheet from 'react-jss'
import styles from './activity-action.styles.js'

const ActivityAction = ({ classes, activity }) => {
  const actor = activity && activity.originator.display_name
  const actions = {
    CommentApproved:
      activity.activity_type === 'CommentApproved' &&
      `${actor} commented on ${activity.activity_object.work.title} by ${activity.activity_object.work.author.display_name}`,
    WorkPublished:
      activity.activity_type === 'WorkPublished' &&
      `${actor} posted ${activity.activity_object.title} by ${activity.activity_object.author.display_name}`,
    SchoolFollowed:
      activity.activity_type === 'SchoolFollowed' &&
      `${actor} started following ${activity.activity_object.title}`,
    SchoolUnfollowed:
      activity.activity_type === 'SchoolUnfollowed' &&
      `${actor} unfollowed ${activity.activity_object.title}`,
    PresentationShared:
      activity.activity_type === 'PresentationShared' &&
      `${actor} shared a lesson "${activity.activity_object.presentation.title}" with you`,
    ParentAddedToStudent:
      activity.activity_type === 'ParentAddedToStudent' &&
      `You are now registred as a parent of ${activity.activity_object.display_name}`,
    GuestModeratorInvited:
      activity.activity_type === 'GuestModeratorInvited' &&
      `${actor} invited you to moderate an assessment file`,
    ContributionMarkedAsConclusion:
      activity.activity_type === 'ContributionMarkedAsConclusion' &&
      `${actor} has selected a conclusion to an assessment file you contributed to`,
    PaPresentationActionCreated:
      activity.activity_type === 'PaPresentationActionCreated' &&
      `${actor} has shared a lesson with you`,
    WorkSubmittedByStudent:
      activity.activity_type === 'WorkSubmittedByStudent' &&
      `${actor} sent you work to mark`,
    WorkApprovedByTeacher:
      activity.activity_type === 'WorkApprovedByTeacher' &&
      `${actor} approved your work`,
    WorkReturnedByTeacher:
      activity.activity_type === 'WorkReturnedByTeacher' &&
      `${actor} asked you to review your work`,
    WorkSentToPeer:
      activity.activity_type === 'WorkSentToPeer' &&
      `${actor} sent you work for review`,
    WorkSentBackFromPeer:
      activity.activity_type === 'WorkSentBackFromPeer' &&
      `${actor} sent you work for review`
  }

  return (
    <div className={classes.container}>
      {actions[activity.activity_type] || ''}
    </div>
  )
}

export default injectSheet(styles)(ActivityAction)
