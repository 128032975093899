import React from 'react'
import injectSheet from 'react-jss'
import styles from './button-element.styles.js'
import classNames from 'classnames'

const ButtonElement = ({ nodeRef, classes, className, type, ...props }) => {
  return (
    <button
      ref={nodeRef}
      type={type || 'button'}
      className={classNames(classes.button, className)}
      {...props}
    />
  )
}

export default injectSheet(styles)(ButtonElement)
