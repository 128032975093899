import React, { useContext } from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import HeaderContext from '../context'
import { useSubscriptionPolicy } from 'pobble-components/dist/subscriptions'

const NavItem = injectSheet({
  listItem: {
    '@media (min-width: 769px)': {
      marginLeft: '0'
    }
  },
  nonExpandable: {
    '@media (max-width: 768px)': {
      padding: '24px 0',
      borderBottom: '1px solid #ececec'
    }
  },
  navLink: {
    color: 'var(--gray)',
    fontWeight: 'bold',
    '& *': {
      pointerEvents: 'none'
    },
    '@media (min-width: 769px)': {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      height: '70px',
      verticalAlign: 'top',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#fff !important',
      transition: 'color 0.3s',
      padding: '16px',
      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.1)'
      }
    }
  },
  icon: {
    display: 'none',
    marginRight: '8px',
    opacity: '.7',
    '@media (min-width: 769px)': {
      display: 'block'
    }
  },
  expandIcon: {
    display: 'none',
    '@media (min-width: 769px)': {
      display: 'inline-block',
      fontSize: '.8em',
      marginLeft: '.5em',
      marginBottom: '.3em'
    }
  },
  activeLink: {
    '@media (min-width: 769px)': {
      background: 'rgba(0, 0, 0, 0.14)',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.14)'
      }
    }
  }
})(
  ({
    classes,
    className,
    linkClassName,
    icon,
    children,
    url,
    active,
    onClick,
    expandable,
    subscriptionPolicy = () => ({ restricted: false, plan: 'pobble-bronze' }),
    ...props
  }) => {
    const { me, student } = useContext(HeaderContext)
    const SP = useSubscriptionPolicy(subscriptionPolicy)

    onClick = !subscriptionPolicy
      ? onClick
      : e => {
          if (SP.restricted) {
            e.preventDefault()
            SP.showDialog()
          }
        }

    return (
      <li
        className={classNames(
          classes.listItem,
          { [classes.nonExpandable]: !expandable },
          className
        )}>
        <a
          className={classNames(classes.navLink, linkClassName, {
            [classes.activeLink]: active,
            [classes.studentNavLink]:
              student || (me && me.account && me.account.student_type)
          })}
          href={url}
          onClick={onClick}
          {...props}>
          {icon && <span className={classes.icon}>{icon}</span>}
          <span>{children}</span>
          {expandable && (
            <FontAwesomeIcon
              className={classes.expandIcon}
              icon={faCaretDown}
            />
          )}
        </a>
      </li>
    )
  }
)

export default NavItem
