import React, { memo } from 'react'
import injectSheet from 'react-jss'
import styles from './pagination.styles'
import ReactPaginate from 'react-paginate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons/faCaretLeft'
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight'
import classNames from 'classnames'

const Pagination = memo(
  ({
    classes,
    currentPage,
    totalPages,
    className,
    onPageChange,
    scrollRef,
    ...props
  }) => {
    return totalPages <= 1 ? null : (
      <ReactPaginate
        previousLabel={<FontAwesomeIcon icon={faCaretLeft} />}
        nextLabel={<FontAwesomeIcon icon={faCaretRight} />}
        containerClassName={classNames(classes.container, className)}
        pageLinkClassName={classes.pageLink}
        breakLinkClassName={classes.breakLinkClassName}
        activeLinkClassName={classes.activeLinkClassName}
        previousLinkClassName={classes.previousLinkClassName}
        nextLinkClassName={classes.nextLinkClassName}
        onPageChange={state => {
          onPageChange({ ...state, selected: state.selected + 1 })
          if (scrollRef) {
            document.documentElement.scrollTop =
              scrollRef.current.getBoundingClientRect().top +
              document.documentElement.scrollTop
          }
        }}
        marginPagesDisplayed={1}
        forcePage={currentPage - 1} // We are counting pages from 1 on Pobble while this plugin counts from zero
        pageCount={totalPages}
        {...props}
      />
    )
  }
)

export default injectSheet(styles)(Pagination)
