const styles = {
  burgerButton: {
    width: '12px',
    height: '10px',
    position: 'relative',
    display: 'inline-block'
  },
  bar: {
    transition: 'transform 0.3s, opacity 0.3s',
    background: '#fff',
    height: '2px',
    width: '100%',
    position: 'absolute',
    left: '0',
    top: '4px',
    '&:first-child': {
      top: '0'
    },
    '&:last-child': {
      top: '8px'
    }
  },
  opened: {
    opacity: '0',
    '&:first-child': {
      opacity: '1',
      transform: 'rotate(45deg) translate3d(3px, 3px, 0)'
    },
    '&:last-child': {
      opacity: '1',
      transform: 'rotate(-45deg) translate3d(2.8px, -2.8px, 0)'
    }
  }
}

export default styles
