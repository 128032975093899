import React from 'react'
import { apiClient } from 'pobble-components/dist/api'
import { useMemo } from 'react'
import { errorHandler } from 'pobble-components/dist/error-handler'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown } from '@fortawesome/pro-solid-svg-icons/faCrown'
import injectSheet from 'react-jss'
import THEME from 'pobble-components/dist/theme'
import classNames from 'classnames'

const goToSelfServicePortal = (options = {}) =>
  apiClient
    .get('/v3/me/subscriptions/chargebee_self_serve_portal_url')
    .then(res => {
      options.beforeRedirect && options.beforeRedirect(res.data.url)
      window.location = res.data.url
    })
    .catch(errorHandler)

const checkoutPlan = (plan, params) =>
  apiClient
    .get(`/v3/me/subscriptions/${plan}`, { params })
    .then(res => (window.location = res.data.url))
    .catch(errorHandler)

const useTimeTillEndPeriod = me => {
  const timeTillEndPeriod = useMemo(() => {
    const endPeriodString = me.account.individual_subscription.period_end
    if (typeof endPeriodString !== 'string') return null
    const endPeriodDate = new Date(endPeriodString)
    return endPeriodDate.getTime() - Date.now()
  }, [me])
  return timeTillEndPeriod
}

const crownStyles = {
  crown: ({ accent }) => ({
    fontSize: '10px',
    color: !accent
      ? THEME.COLORS.TEXT.LIGHT_GREY.NORMAL
      : THEME.COLORS.YELLOW.NORMAL
  })
}

const SubsCrown = injectSheet(crownStyles)(({ classes, className, styles }) => {
  return (
    <FontAwesomeIcon
      className={classNames(classes.crown, className)}
      styles={styles}
      icon={faCrown}
    />
  )
})

export { goToSelfServicePortal, checkoutPlan, useTimeTillEndPeriod, SubsCrown }
