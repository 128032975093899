import React from 'react'
import PobbleUserContext from './context'

const withMe = WrappedComponent => props => (
  <PobbleUserContext.Consumer>
    {({
      me,
      refetchMe,
      request,
      updateMe,
      updateUserState,
      showSchoolRequiredDialog
    }) => (
      <WrappedComponent
        me={me}
        refetchMe={refetchMe}
        meRequest={request}
        updateMe={updateMe}
        updateUserState={updateUserState}
        showSchoolRequiredDialog={showSchoolRequiredDialog}
        {...props}
      />
    )}
  </PobbleUserContext.Consumer>
)

export default withMe
