import React from 'react'

const Arrow = ({ className }) => (
  <svg
    className={className}
    width="11"
    height="7"
    viewBox="0 0 11 7"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Style-Guide" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g
        id="Input-fields-and-dropdowns"
        transform="translate(-1445 -214)"
        stroke="currentColor">
        <g id="Default-dropdown" transform="translate(1254 129)">
          <g id="Down-arrow---active" transform="translate(191 86)">
            <path
              d="M0.14957265,0.138888889 L5.23504274,4.86111111"
              id="Line-2"
            />
            <path d="M10,0 L5.38461538,5" id="Line-2-Copy" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Arrow
