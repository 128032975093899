const COLORS_MAP = {
  CgIJCgYPDQA: '#FFF27F',
  Cw4FBAIHCAE: '#7FD6F7',
  BwEABwQJBwc: '#F4A691',
  AgwJBwQPBw8: '#929FC7',
  BwECCgYNAwA: '#CFDE8B',
  DQEMBwkCBgg: '#C0AED7',
  AwkPCw0PDAo: '#A4A4A4',
  Cw4GDQcCDgI: '#FFFFFF'
}

const COLOR_KEYS_IN_ORDER = [
  'CgIJCgYPDQA',
  'Cw4FBAIHCAE',
  'BwEABwQJBwc',
  'AgwJBwQPBw8',
  'BwECCgYNAwA',
  'DQEMBwkCBgg',
  'AwkPCw0PDAo',
  'Cw4GDQcCDgI'
]

export { COLORS_MAP, COLOR_KEYS_IN_ORDER }
