import React from 'react'
import injectSheet from 'react-jss'
import styles from './burger-button.styles'
import classNames from 'classnames'

const BurgerButton = ({ classes, opened = true }) => (
  <span className={classes.burgerButton}>
    <span className={classNames(classes.bar, { [classes.opened]: opened })} />
    <span className={classNames(classes.bar, { [classes.opened]: opened })} />
    <span className={classNames(classes.bar, { [classes.opened]: opened })} />
  </span>
)

export default injectSheet(styles)(BurgerButton)
