import React, { useEffect, useState, useMemo, useContext } from 'react'
import injectSheet from 'react-jss'
import Button from 'pobble-components/dist/button'
import { URLS } from 'pobble-components/dist/setup'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { apiClient } from 'pobble-components/dist/api'
import pupils from 'pobble-components/dist/subscriptions/pupils.gif'
import { SubscriptionContext } from 'pobble-components/dist/subscriptions'

const currencyMap = {
  AUD: 'A$',
  NZD: 'NZ$',
  USD: '$',
  GBP: '£'
}

const UpgradeButton = ({ trigger }) => {
  return (
    <Button
      className={`metrics__subscription_modal ${
        trigger ? `metrics__subscription_modal--${trigger}` : ''
      }`}
      action={() => {
        window.location = `${URLS.ACCOUNT}/membership${
          trigger ? `?from=${trigger}` : ''
        }`
      }}
      tall>
      Browse subscription options
    </Button>
  )
}

const pointStyles = ({ COLORS }) => ({
  point: {
    display: 'flex',
    '& p': {
      margin: '0'
    },
    '& > svg': {
      color: COLORS.BLUE.NORMAL,
      fontSize: '18px',
      marginRight: '10px'
    }
  }
})

const Point = injectSheet(pointStyles)(({ children, classes }) => {
  return (
    <li className={classes.point}>
      <FontAwesomeIcon icon={faCheckCircle} />
      <p>{children}</p>
    </li>
  )
})

const styles = ({ FONT_SIZES }) => ({
  container: {
    fontSize: '14px',
    '& button': {
      width: '240px',
      display: 'inline-flex',
      justifyContent: 'center'
    },
    '@media (min-width: 451px)': {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      '& > *': {
        width: '50%'
      }
    }
  },
  pitch: {
    textAlign: 'center',
    '@media (min-width: 451px)': {
      padding: '40px 10px 40px 38px'
    },
    '@media (min-width: 769px)': {
      padding: '40px 0 40px 40px'
    },
    '& h2': {
      ...FONT_SIZES.HEADING_MEDIUM.STYLE,
      margin: '0 0 14px',
      '@media (min-width: 769px)': {
        ...FONT_SIZES.HEADING_BIG.STYLE
      }
    },
    '& p': {
      margin: '0'
    },
    '& img': {
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      marginBottom: '24px',
      '@media (min-width: 768px)': {
        marginBottom: '32px'
      }
    },
    '& button': {
      margin: '28px 0 0',
      display: 'none',
      '@media (min-width: 451px)': {
        display: 'inline-flex'
      }
    }
  },
  description: {
    borderTop: '1px solid #ececec',
    paddingTop: '24px',
    marginTop: '24px',
    '@media (min-width: 451px)': {
      borderTop: 'none',
      borderRight: '1px solid #ececec',
      margin: '0',
      padding: '20px 40px 20px 0'
    },
    '& > p': {
      margin: '24px 0'
    }
  },
  points: {
    listStyle: 'none',
    margin: '24px 0',
    padding: '0',
    '@media (min-width: 451px)': {
      borderTop: 'none',
      margin: '30px 0'
    },
    '& li': {
      marginTop: '16px',
      '&:first-child': {
        marginTop: '0'
      }
    }
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'center',
    margin: '40px 0 0',
    '@media (min-width: 451px)': {
      display: 'none'
    }
  }
})

const SubscriptionRequired = ({ classes, className }) => {
  const [priceData, setPriceData] = useState(null)

  const price = useMemo(() => {
    if (!priceData) return null
    return `${currencyMap[priceData.subscription_currency]}${parseInt(
      priceData.subscription_prices.annual_per_month['pobble_moderation']
    )}`
  }, [priceData])

  const { subsDialogState } = useContext(SubscriptionContext)

  useEffect(() => {
    apiClient
      .get('/v3/me', {
        params: {
          include: 'account.subscription_currency,account.subscription_prices'
        }
      })
      .then(res => setPriceData(res.data.account))
  }, [])

  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.pitch}>
        <img src={pupils} width="453" height="224" alt="Pupils" />
        <h2>
          {subsDialogState.title
            ? subsDialogState.title
            : 'You need a Pobble subscription to do that'}
        </h2>
        <p>Subscription plans start from just {price}/month.</p>
        <UpgradeButton trigger={subsDialogState.trigger} />
      </div>
      <div className={classes.description}>
        <p>
          With a Pobble subscription you can present all premium lessons and
          resource types, including:
        </p>
        <ul className={classes.points}>
          <Point>Pobble 365 +</Point>
          <Point>Theme-based, curriculum mapped lessons</Point>
          <Point>Quick writes/Lesson starters</Point>
          <Point>Literacy through sport</Point>
          <Point>Pobble news</Point>
          <Point>Success criteria/Word banks</Point>
          <Point>Exemplar texts</Point>
        </ul>
        <p>
          You can also edit and share unlimited Pobble content through
          printing/pdf, creating a lesson link (for 3rd party applications) and
          through Pobble's pupil tools.
        </p>
        <p>
          You'll also be able to access our exemplification materials for online
          writing moderation.
        </p>
      </div>
      <div className={classes.buttonHolder}>
        <UpgradeButton trigger={subsDialogState.trigger} />
      </div>
    </div>
  )
}

export default injectSheet(styles)(SubscriptionRequired)
